import React from 'react';
import { Image, Dropdown, DropdownTrigger, DropdownMenu, DropdownSection, DropdownItem } from "@nextui-org/react";
import { Link, useNavigate } from 'react-router-dom';
import { SignOut, ListBullets, CaretDown } from "@phosphor-icons/react";
import './Header.scss';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';

export default function Header({ toggleResponsiveSidebar }) {

    const navigate = useNavigate();
    const token = localStorage.getItem("k_star_token");
    const username = localStorage.getItem('k_star_name');
    const profile_photo = localStorage.getItem('k_star_photo');
    const surname = localStorage.getItem('k_star_surname')

    const handleLogout = async () => {
        try {
            // Make a POST request to the server logout endpoint
            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}/auth/logout`,
                {},
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            if (response.status === 200) {
                // Successful logouts
                localStorage.removeItem("k_star_token");
                localStorage.removeItem("page");
                localStorage.removeItem("pageRow");
                // Redirect to the login page
                navigate('/login');

            } else {
                console.error('Logout failed:', response.data.message);
                toast.error('Logout failed. Please try again.');
            }
        } catch (error) {
            // Handle errors during logout
            console.error('Error during logout:', error);
        }
    };
    function capitalizedString(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }
    const renderProfilePhotoOrPlaceholder = () => {
        console.log("UserName : ", profile_photo);
        if (profile_photo) {
            return <Image alt="Profile" className='' src={profile_photo} />;
        } else {
            // If profile_photo is not available, use the first letter of username
            // Adjust the styles as needed
            console.log("UserName : ", profile_photo);
            const firstLetter = username.charAt(0).toUpperCase();
            return (
                <div className='font-bold text-xl'>
                    {firstLetter}
                </div>
            );
        }
    };

    return (
        <>
            <header className='sticky top-0 start-0 end-0 z-10 bg-white'>
                <div className='flex items-center justify-between header'>
                    <div className='flex items-center gap-4'>
                        <ListBullets size={30} color="#004f9c" weight="bold" className='block lg:hidden' onClick={toggleResponsiveSidebar} />
                        <Link to="/dashboard" className='flex items-center gap-4.5' >
                            <Image alt="Navbar" src="/navbar-img/logo.png" />
                            <h2 className='text-dark-blue font-semibold'>K. STAR EXPORT</h2>
                        </Link>
                    </div>
                    <div className="cursor-pointer">
                        <Dropdown
                            showArrow
                            radius="sm"
                            placement='top-end'
                            className='z-0'
                            classNames={{
                                base: "before:bg-white before:border before:border-graythree",
                                content: "p-0 border border-graythree shadow-none",
                            }}
                        >
                            <DropdownTrigger>
                                <div className='flex items-center gap-3.5'>
                                    <div className='flex items-center gap-2.5.25'>
                                        <div className='flex items-center justify-center profile-icon'>
                                            {/* <p className='text-black text-5 font-bold leading-7'>P</p> */}
                                            {/* <Image alt="Profile" src={profile_photo} width={32} height={32} className="rounded-full" /> */}
                                            {renderProfilePhotoOrPlaceholder()}
                                        </div>
                                        <div className='hidden sm:block'>
                                            <p className='text-3.75 leading-4.5'>{capitalizedString(username)} {capitalizedString(surname)}</p>
                                        </div>
                                    </div>
                                    <div className='hidden sm:block'>
                                        <CaretDown size={16} color="#757575" weight="bold" />
                                    </div>
                                </div>
                            </DropdownTrigger>
                            <DropdownMenu
                                aria-label="Custom item styles"
                                className="p-2 z-0"
                                itemClasses={{
                                    base: [
                                        "rounded-md",
                                        "data-[hover=true]:bg-default-100",
                                    ],
                                }} >
                                <DropdownSection aria-label="Profile & Actions" className="mb-0">
                                    <DropdownItem key="profile" className="opacity-100 p-2 outline-none" onClick={handleLogout}>
                                        <Link to="" className='flex items-center gap-2.5' >
                                            <SignOut size={17} weight="bold" color="#3d3b3b" />
                                            <p className='text-darktwo text-sm leading-4'>Sign Out</p>
                                        </Link>
                                    </DropdownItem>
                                </DropdownSection>
                            </DropdownMenu>
                        </Dropdown>
                    </div>
                </div>
            </header>
        </>
    )
}
