import React from 'react'
import { Link } from 'react-router-dom';


export default function Dashboard() {
    return (
        <>
            <div className=' absolute top-2/4 bottom-2/4 translate-y-[-50%] h-full flex items-center justify-center text-center start-5 end-5'>
                <div>
                    <div className='lg:text-2xl text-xl font-semibold  text-dark-blue'>Welcome to</div>
                    <img alt='Dashbaord Image Logo' src='/Logo.png' className='mx-auto mt-6' />
                    
                    <h5 className='sm:text-3xl text-5.5 uppercase text-dark-blue sm:font-semibold font-bold mt-2'>K. STAR EXPORT</h5>
                    <div className='mt-12 text-dark-blue lg:text-4xl sm:text-3xl text-2xl font-bold'>
                        Tender Management System
                    </div>
                    <div className='mt-10'>
                        <Link to="/open-tender" className="sm:py-3 sm:px-5 py-2 px-4 rounded-1.25 text-white font-medium lg:text-base sm:text-3.75 text-sm bg-dark-blue">Show Open Tenders</Link>
                    </div>
                </div>
            </div >
        </>
    )
}
