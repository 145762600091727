import React, { useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { ProtectedRoute } from "./components/ProtectedRoute";
import Header from "./components/header";
import Sidebar from "./components/sidebar";
import Dashboard from "./dashboard";
import OpenTender from "./opentender";
import Login from "./varification";
import Getotp from "./varification/Getotp";
import Register from "./varification/Register";
import Viewbid from "./viewbid";
import ViewBidHistory from "./viewbidhistory";
import ViewTender from "./viewtender";
import Edittender from "./viewtender/Edittender";
import Viewwonbids from "./viewwonbids";
import Wonbids from "./wonbids";

const MainLayout = ({ toggleResponsiveSidebar, isResponsiveSidebar }) => {
  return (
    <div
      className={`admin-content ${
        isResponsiveSidebar ? "responsive-sidebar" : ""
      }`}
    >
      <Header toggleResponsiveSidebar={toggleResponsiveSidebar} />
      <div className="flex justify-end main-content">
        <Sidebar
          toggleSidebar={toggleResponsiveSidebar}
          isResponsiveSidebar={isResponsiveSidebar}
        />
        <div className="body-content flex flex-col relative">
          <Routes>
            <Route path="/dashboard" element={<Dashboard />} />
            {/* Add other routes here */}
            <Route path="/open-tender" element={<OpenTender />} />
            <Route
              path="/open-tender/view-tender/:tenderId"
              element={<ViewTender />}
            />
            <Route
              path="/open-tender/edit-tender/:tenderId"
              element={<Edittender />}
            />
            <Route path="/won-bids" element={<Wonbids />} />
            <Route
              path="/won-bids/view-won-bids/:tenderId"
              element={<Viewwonbids />}
            />
            <Route
              path="/open-tender/view-bid/:tenderId"
              element={<Viewbid />}
            />
            <Route path="/view-bid/:tenderId" element={<Viewbid />} />
            <Route path="/view-bid" element={<ViewBidHistory />} />
          </Routes>
        </div>
      </div>
    </div>
  );
};

export default function App() {
  const [isResponsiveSidebar, setResponsiveSidebar] = useState(false);

  const toggleResponsiveSidebar = () => {
    setResponsiveSidebar(!isResponsiveSidebar);
  };

  const isAuthenticated = !!localStorage.getItem("k_star_token");

  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/login" element={<Login />} />
          <Route path="/getotp" element={<Getotp />} />
          <Route path="/register" element={<Register />} />
          <Route
            path="*"
            element={
              <ProtectedRoute>
                <MainLayout
                  toggleResponsiveSidebar={toggleResponsiveSidebar}
                  isResponsiveSidebar={isResponsiveSidebar}
                />
              </ProtectedRoute>
            }
          />
        </Routes>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </BrowserRouter>
    </>
  );
}
