import React, { useState, useMemo, useEffect, useRef } from "react";
import {
  Chip,
  Table,
  TableHeader,
  TableColumn,
  TableBody,
  TableRow,
  TableCell,
  Input,
  DropdownMenu,
  DropdownItem,
  Pagination
} from "@nextui-org/react";
import {
  X,
  ArrowLeft,
  CaretDoubleLeft,
  CaretDoubleRight,
  CaretLeft,
  CaretRight,
} from "@phosphor-icons/react";
import { Link, useParams } from "react-router-dom";
import { edit_bids } from "./Data";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "./Style.scss";

export default function Viewwonbids() {
  // Table Pagination
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(50);


  const [customers, setCustomers] = useState([]);
  const [loading, setLoading] = useState(true);

  const messagesEndRef = useRef(null)


  const { tenderId } = useParams();



  const fetchTenders = async () => {
    try {
      // Add a check to ensure selectedCustomer is not null
      const token = localStorage.getItem("k_star_token");
      const apiUrl = `${process.env.REACT_APP_API_URL}/auth/getCustomerWonBid`;
      const response = await fetch(`${apiUrl}`, {
        headers: {
          Authorization: `Bearer ${token}`, // Use the retrieved token in the headers
        },
      });

      const data = await response.json();

      if (!response.ok) {
        console.error("Error fetching customers:", data);
        // Handle the error, e.g., show an error message to the user

        return;
      }

      // The request was successful (status code 2xx)
      const matchedTender = data.filter((tender) => tender._id === tenderId);
      if (matchedTender.length > 0) {
        setCustomers(matchedTender[0]);

      }

      //   setSelectedCustomer(data);
    } catch (error) {
      console.error("Error fetching customers:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    // Fetch tenders when the component mounts
    fetchTenders("won_bids");
  }, []);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredCustomers, setFilteredCustomers] = useState(customers.lots);
  useEffect(() => {
    if (searchTerm.trim() === '') {
      setFilteredCustomers(customers.lots)
    }
    else {
      // Filter the tenders based on the search term
      console.log("Customer", customers)
      const filtered = customers.lots.filter(customer =>

        customer?.description.toLowerCase().includes(searchTerm.toLowerCase()) ||
        customer?.lot_num.toLowerCase().includes(searchTerm.toLowerCase())
        //customer.mobileNumber.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredCustomers(filtered);
    }
  }, [customers.lots, searchTerm]);



  const updateSearchTerm = (event) => {
    setSearchTerm(event.target.value);
  };
  // Table Pagination
  // const pages = useMemo(() => Math.ceil(customers.lots?.length / rowsPerPage), [customers, rowsPerPage]);
  // const pages = Math.ceil(selectedTender.length / rowsPerPage);
  const pages = useMemo(() => Math.ceil((filteredCustomers?.length || 0) / rowsPerPage), [filteredCustomers, rowsPerPage]) || 10;
  const items = useMemo(() => {
    const start = (page - 1) * rowsPerPage;
    const end = start + rowsPerPage;
    return filteredCustomers?.slice(start, end) || [];
  }, [filteredCustomers, page, rowsPerPage]);

  const handlePageSizeChange = (e) => {
    setRowsPerPage(Number(e.target.value));
    setPage(1);
  };

  return (
    <>
      {loading ? (
        <div className="loader-container">
          <div className="loader"></div>
        </div>
      ) : (
        <>

          <div className="body-conetnt-header bg-grayeight">
            <div className=" inline-flex w-full justify-between gap-5  ">
              <div className="flex-grow">
                <div className="flex xl:flex-row flex-col xl:items-center flex-wrap gap-5">
                  <div className="flex items-center gap-10  ">
                    <div className="flex items-center gap-7">
                      <Link to="/won-bids" className="back_arrow">
                        <ArrowLeft size={24} weight="bold" />
                      </Link>
                      <h5 className="text-xl leading-8.25 text-blackone font-semibold ">
                        {customers?.name}
                      </h5>
                    </div>
                  </div>
                  <div className="flex-grow z-0">
                    <div className="flex items-center justify-between gap-5">
                      <div className="order-2 xl:order-1">
                        {customers?.status === "EXPIRED" && (
                          <Chip className="chip text-redtwo bg-light-red">
                            {customers?.status}
                          </Chip>
                        )}
                        {customers?.status === "CLOSED" && (
                          <Chip className="chip text-redtwo bg-light-red">
                            {customers?.status}
                          </Chip>
                        )}
                        {customers?.status === "ACTIVE" && (
                          <Chip className="chip text-green bg-light-green">
                            {customers?.status}
                          </Chip>
                        )}
                        {customers?.status === "INQUE" && (
                          <Chip className="chip text-orange bg-light-orange">
                            {customers?.status}
                          </Chip>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <Link to="/won-bids" className="cancle_arrow">
                <X size={24} color="#7e7e7e" weight="bold" />
              </Link>
            </div>
            {customers && (
              <div className='bg-white mt-7.5 border border-mutedeight rounded-2.5'>
                <div className='flex items-start tender_details  md:py-4 py-6 px-13'>
                  <div className='flex items-start md:justify-between bidtender_info'>
                    <div>
                      <span className='whitespace-nowrap'>START DATE</span>
                      <p className='text-darkone '>
                        {customers.startDate &&
                          new Date(customers.startDate).toLocaleString("en-GB", {

                            day: "2-digit",
                            month: "2-digit",
                            year: "numeric",
                            hour: "2-digit",
                            minute: "2-digit",
                            hour12: true
                          }).replace('am', 'AM').replace('pm', 'PM')}

                      </p>
                    </div>
                    <div>
                      <span className='whitespace-nowrap'>END DATE</span>
                      <p className='text-darkone '>
                        {customers.endDate &&
                          new Date(customers.endDate).toLocaleString("en-GB", {

                            day: "2-digit",
                            month: "2-digit",
                            year: "numeric",
                            hour: "2-digit",
                            minute: "2-digit",
                            hour12: true
                          }).replace('am', 'AM').replace('pm', 'PM')}

                      </p>
                    </div>
                    {/* <div>
                      <span className='whitespace-nowrap'>REMAINING TIME</span>
                      <p className='text-redthree '>10 Hrs 14 Mins</p>
                    </div> */}
                  </div>
                  <div className='bids_texts bidsboxlist'>
                    <div>
                      <span className='whitespace-nowrap'>YOUR BIDS</span>
                      <p className='text-darkone font-semibold'>{customers.userBidCount}/{customers.totalLots}</p>
                    </div>
                    <div className='yourbid_texts'>
                      <span className='whitespace-nowrap'>YOUR BID VALUE</span>
                      <p className='text-dark-blue font-bold'>
                        {/* {customers.lots.reduce((p, c) => { return c.userBidValue !== undefined ? p + c.total_carat * c.userBidValue : p }, 0)} */}

                        ${customers.lots.reduce((p, c) => { return c.userBidValue !== undefined ? p + c.total_carat * c.userBidValue : p }, 0).toFixed(2)}
                      </p>
                    </div>
                    <div>
                      <span className='whitespace-nowrap'>WON BIDS</span>
                      <p className='text-darkone font-semibold'>{customers.countWonBids}</p>
                    </div>
                    <div>
                      <span className='whitespace-nowrap'>WON BID VALUE</span>
                      <p className='text-green  font-bold'>
                        ${customers.lots.reduce((p, c) => { return c.bidStatus == 'won' ? p + c.total_carat * c.userBidValue : p }, 0).toFixed(2)}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className=" pt-7.5 pb-20 mt-2.5 relative">
            <div className=" md:absolute end-7.5 top-2 md:mx-0 mx-7.5">
              <Input
                className="py-0 md:mb-0 mb-4  search-input sm:w-[397px] w-full "
                // radius="sm"
                clearable
                placeholder="Search"
                classNames={{
                  input: ["bg-transparent", "text-blackone "],
                  innerWrapper: "bg-transparent ",
                  inputWrapper: [
                    "bg-transparent border border border-mutedseven shadow-none py-0",
                    "md:h-11 h-10",
                    "placeholder:text-mutedtwo",
                    "!cursor-text",
                  ],
                }}
                type="text"
                value={searchTerm}
                onChange={updateSearchTerm}
              />
            </div>

            <div>
              <h6 className="text-lg text-dark-blue font-semibold leading-6.25 px-7.5  border-b-2 border-dark-blue inline-block">
                <p className="mb-2.5">Bid Detail</p>
              </h6>
            </div>
            <div ref={messagesEndRef} className="overflow-auto">
              <Table
                radius="none"
                removeWrapper
                className="custom-table border-t-2 border-graytwo"
                aria-label="Example static collection table"
              >
                <TableHeader>
                  <TableColumn>LOT NO.</TableColumn>
                  <TableColumn>DESCRIPTION</TableColumn>
                  <TableColumn>SIZE</TableColumn>
                  <TableColumn>NO. OF STONE</TableColumn>
                  <TableColumn>TOTAL CARAT</TableColumn>
                  <TableColumn>YOUR BID VALUE ($/CT)</TableColumn>
                  <TableColumn>TOTAL VALUE</TableColumn>
                  <TableColumn></TableColumn>
                </TableHeader>
                <TableBody>
                  {items?.map((props) => (
                    <TableRow key={props.key}>
                      <TableCell className="">
                        <div>{props.lot_num}</div>
                      </TableCell>
                      <TableCell>
                        <div>{props.description}</div>
                      </TableCell>
                      <TableCell>
                        <div>{props.size || '-'}</div>
                      </TableCell>
                      <TableCell>
                        <div>{props.no_stone || '-'}</div>
                      </TableCell>
                      <TableCell>
                        <div>{props.total_carat}</div>
                      </TableCell>
                      <TableCell>
                        <div className="text-blueone font-semibold">
                          {props.userBidValue > 0 ? '$'.concat((props.userBidValue).toFixed(2)) : "-"}
                        </div>
                      </TableCell>
                      <TableCell>
                        <div className="text-blueone font-semibold">
                          {props.userBidValue * props.total_carat > 0 ? '$' + parseFloat((props.userBidValue) * props.total_carat).toFixed(2) : "-"}
                        </div>
                      </TableCell>
                      <TableCell>
                        <p className="font-semibold text-lg text-green custom-width capitalize">
                          {props.bidStatus === 'won' ? 'won' : <X size={26} color="#FF0000" weight="bold" />}
                        </p>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>

            {/* Pagination */}
            {items && items.length ? <div className="lg:px-7.5 px-5 paginations">
              <div className="flex flex-wrap justify-center sm:justify-end items-center gap-5 sm:gap-14 py-5.5">
                <div className="flex items-center gap-2">
                  <p className="text-darkone text-sm leading-4.5.25 font-medium">
                    Page size
                  </p>
                  <select
                    className="focus:outline-none font-medium text-sm text-darkone w-12 cursor-pointer"
                    onChange={handlePageSizeChange}
                    value={rowsPerPage}
                  >

                    <option value="50">50</option>
                    <option value="100">100</option>
                    <option value="150">150</option>
                    <option value="200">200</option>
                  </select>
                </div>

                <Pagination
                  // isCompact
                  showControls
                  total={pages}
                  // total={10}
                  page={page}
                  // color="warning"
                  classNames={{
                    wrapper: "rounded ",
                    item: "rounded ",
                    cursor:
                      "bg-dark-blue  text-white rounded",
                  }}

                  initialPage={1}
                  onChange={(newPage) => {
                    setPage(newPage);
                    messagesEndRef?.current?.scrollIntoView({ behavior: "smooth" });
                  }}
                />
              </div>
            </div> :null}
          </div>
        </>
      )}
    </>
  );
}
