import {
  Button,
  Chip,
  Input,
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
} from "@nextui-org/react";
import { ArrowLeft, X } from "@phosphor-icons/react";
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./Style.scss";

export default function ViewTender() {
  const [tender, setTender] = useState({});
  const [lotBids, setLotBids] = useState([]);
  const [totalLotBid, setTotalLotBid] = useState(0);
  const [totalBids, setTotalBids] = useState();
  const [loading, setLoading] = useState(true);
  const [submitLoading, setSubmitLoading] = useState(false);
  const { tenderId } = useParams();

  const navigate = useNavigate();
  const handleCancelClick = () => {
    navigate("/open-tender");
  };

  const handleBidChange = (lotId, bidValue, carat) => {
    const bidValueNumeric = bidValue ? parseFloat(bidValue) : "";

    setLotBids((prevLotBids) =>
      prevLotBids.map((bid) =>
        bid.lotId === lotId ? { ...bid, price: bidValueNumeric } : bid
      )
    );
    setTotalLotBid((prevTotal) => {
      const newTotal = lotBids.reduce((acc, currentBid) => {
        const totalForLot =
          currentBid.lotId === lotId
            ? bidValueNumeric * carat
            : currentBid.price * currentBid.carat;
        return acc + totalForLot;
      }, 0);
      return newTotal;
    });
  };

  const fetchData = async () => {
    const token = localStorage.getItem("k_star_token");
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_URL}/tender/lots/${tenderId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setTender(res.data);
      const userBidsTotal = res.data.lots.reduce((acc, lot) => {
        // Count only bids made by the user
        const userBids = lot.bids.filter(
          (bid) => bid.userId === lot?.userId
        ).length;
        return acc + userBids;
      }, 0);

      const initialLotBids = res.data.lots.map((lot) => ({
        lotId: lot._id,
        price: lot.bids.find((b) => b.userId === lot?.userId)?.price || "",
        carat: lot.total_carat,
      }));
      setLotBids(initialLotBids);

      setTotalBids(userBidsTotal);
    } catch (error) {
      console.log("Error While Fetching lots data : ", error);
    } finally {
      setLoading(false);
    }
  };

  const createBid = async () => {
    try {
      setSubmitLoading(true);
      const token = localStorage.getItem("k_star_token");
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/tender/lots/:lotId/bids`,
        lotBids,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (tender?.lots?.length < totalBids) {
        setTotalBids(totalBids + 1);
      }
      toast.success("Bid created successfully!");
      setSubmitLoading(false);
      navigate("/open-tender");
    } catch (error) {
      console.log("Error while Creating Bids : ", error);
      toast.error("Error creating bid. Please try again.");
    }
  };

  useEffect(() => {
    fetchData();
  }, [tenderId]);
  const inputRefs = useRef([]);

  const focusNextInput = (index, isShiftPressed) => {
    const nextIndex = isShiftPressed ? index - 1 : index + 1;
    if (nextIndex >= 0 && nextIndex < inputRefs.current.length) {
      inputRefs.current[nextIndex].focus();

      // Scroll to the top of the focused input
      const inputTop = inputRefs.current[nextIndex].getBoundingClientRect().top;
      const offset = window.scrollY + inputTop - 16; // Adjust the offset if needed
      window.scrollTo({ top: offset, behavior: "smooth" });
    }
  };

  useEffect(() => {
    setTotalLotBid(
      lotBids.reduce(
        (acc, currentBid) =>
          acc + (currentBid.price || 0) * (currentBid.carat || 0),
        0
      )
    );
  }, [lotBids]);
  return (
    <>
      {loading ? (
        <div className="loader-container">
          <div className="loader"></div>
        </div>
      ) : (
        <>
          <div className="body-conetnt-header bg-grayeight">
            <div className="inline-flex w-full justify-between gap-5  ">
              <div className="flex-grow">
                <div className="flex xl:flex-row flex-col xl:items-center flex-wrap gap-5">
                  <div className="flex items-center gap-10  ">
                    <div className="flex items-center gap-7">
                      <Link to="/open-tender" className="back_arrow">
                        <ArrowLeft size={24} weight="bold" />
                      </Link>
                      <h5 className="text-xl leading-8.25 text-blackone font-semibold ">
                        {tender?.name}
                      </h5>
                    </div>
                  </div>
                  <div className="flex-grow z-0">
                    <div>
                      <Chip className="chip text-green bg-light-green">
                        {tender?.status}
                      </Chip>
                    </div>
                  </div>
                </div>
              </div>
              <Link to="/open-tender" className="cancle_arrow">
                <X size={24} color="#7e7e7e" weight="bold" />
              </Link>
            </div>
            <div className="bg-white mt-7.5 border border-mutedeight rounded-2.5">
              <div className="flex items-start tender_details md:py-4 py-6 px-13">
                <div className="flex items-start md:justify-between tender_info">
                  <div>
                    <span className="whitespace-nowrap">START DATE</span>
                    <p className="text-darkone ">
                      {tender.startDate &&
                        new Date(tender.startDate)
                          .toLocaleString("en-GB", {
                            day: "2-digit",
                            month: "2-digit",
                            year: "numeric",
                            hour: "2-digit",
                            minute: "2-digit",
                            hour12: true,
                          })
                          .replace("am", "AM")
                          .replace("pm", "PM")}
                    </p>
                  </div>
                  <div>
                    <span className="whitespace-nowrap">END DATE</span>
                    <p className="text-darkone ">
                      {tender.endDate &&
                        new Date(tender.endDate)
                          .toLocaleString("en-GB", {
                            day: "2-digit",
                            month: "2-digit",
                            year: "numeric",
                            hour: "2-digit",
                            minute: "2-digit",
                            hour12: true,
                          })
                          .replace("am", "AM")
                          .replace("pm", "PM")}
                    </p>
                  </div>
                  <div>
                    <span className="whitespace-nowrap">REMAINING TIME</span>
                    <p className="text-redthree ">{tender.remainingTime}</p>
                  </div>
                </div>
                {totalBids && totalBids >= 0 ? (
                  <div className="bids_texts">
                    <span className="whitespace-nowrap">YOUR BIDS</span>
                    <p className="text-darkone font-semibold">{`${totalBids}/${tender?.lots?.length}`}</p>
                  </div>
                ) : null}
                <div>
                  <div className="total_value">
                    <span className="whitespace-nowrap">TOTAL BID VALUE</span>
                    <p className="text-dark-blue font-bold">
                      {/* {console.log(tender.lots.reduce((p, c) => {
                          return c.userBidValue? p + c.userBidValue * c.total_carat : p
                        }, 0))} */}
                      {totalLotBid.toFixed(2)}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="pt-7.5 pb-24 mt-2.5">
            <h6 className="text-lg text-dark-blue font-semibold leading-6.25 px-7.5  border-b-2 border-dark-blue inline-block">
              <p className="mb-2.5">Add Bid Detail</p>
            </h6>
            <div className="overflow-auto">
              <Table
                radius="none"
                removeWrapper
                className="custom-table border-t-2 border-graytwo"
                aria-label="Example static collection table"
              >
                <TableHeader>
                  <TableColumn>LOT NO.</TableColumn>
                  <TableColumn>DESCRIPTION</TableColumn>
                  <TableColumn>SIZE</TableColumn>
                  <TableColumn>NO. OF STONE</TableColumn>
                  <TableColumn>TOTAL CARAT</TableColumn>
                  <TableColumn>BID VALUE ($/CT)</TableColumn>
                  <TableColumn>TOTAL VALUE</TableColumn>
                </TableHeader>
                <TableBody>
                  {tender?.lots
                    ?.sort((a, b) => a.lot_num.localeCompare(b.lot_num))
                    ?.sort((a, b) => parseInt(a.lot_num) - parseInt(b.lot_num))
                    ?.map((props, index) => {
                      const bid =
                        lotBids.find((b) => b.lotId === props._id) || {};
                      return (
                        <TableRow key={props._id}>
                          <TableCell className="">
                            <div>{props.lot_num}</div>
                          </TableCell>
                          <TableCell>
                            <div>{props.description}</div>
                          </TableCell>
                          <TableCell>
                            <div>{props.size}</div>
                          </TableCell>
                          <TableCell>
                            <div>{props.no_stone}</div>
                          </TableCell>
                          <TableCell>
                            <div>{props.total_carat}</div>
                          </TableCell>
                          <TableCell className="inputs_box z-0">
                            <Input
                              value={bid.price}
                              ref={(el) => (inputRefs.current[index] = el)}
                              onKeyDown={(e) => {
                                if (e.key === "Tab" && !e.shiftKey) {
                                  e.preventDefault();
                                  focusNextInput(index, false);
                                } else if (e.key === "Tab" && e.shiftKey) {
                                  e.preventDefault();
                                  focusNextInput(index, true);
                                }
                              }}
                              className="py-0 z-0 tender-name custom-box"
                              isClearable
                              classNames={{
                                input: ["bg-transparent", "text-blackone","z-0"],
                                innerWrapper: "bg-transparent z-0 ",
                                inputWrapper: [
                                  "bg-transparent border border border-mutedseven  shadow-none py-0 z-0",
                                  "md:h-11 h-10",
                                  "!cursor-text",
                                ],
                              }}
                              type="number"
                              step="0.01"
                              startContent={
                                <div>
                                  <span className="">$</span>
                                </div>
                              }
                              onChange={(e) =>
                                handleBidChange(
                                  props._id,
                                  e.target.value,
                                  props.total_carat
                                )
                              }
                            />
                          </TableCell>
                          <TableCell>
                            <div className="text-blueone font-semibold">
                              {bid.price && props.total_carat
                                ? `$${(bid.price * props.total_carat).toFixed(
                                    2
                                  )}`
                                : "-"}
                            </div>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </div>

            <div className="tender-foot">
              <div className="lg:px-7.5 px-5 py-5.5 flex items-center justify-end gap-4">
                <Button
                  className="cancletender_btn"
                  onClick={handleCancelClick}
                >
                  <p>CANCEL</p>
                </Button>
                <Button
                  className="savetender_btn"
                  onClick={createBid}
                  isDisabled={tender?.status !== "ACTIVE" || submitLoading}
                >
                  <div className="flex items-center justify-center">
                    <div>SUBMIT </div>
                    {submitLoading && (
                      <div className="small-loader extra-small-loader ms-4"></div>
                    )}
                  </div>
                </Button>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}
