import {
  Button,
  Dropdown,
  DropdownTrigger,
  Image,
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
} from "@nextui-org/react";
import { CaretDown, CaretRight } from "@phosphor-icons/react";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./Style.scss";

export default function OpenTender() {
  // Opened a new page on the Onclick event
  const [loading, setLoading] = useState(true);

  const [allTenders, setAllTenders] = useState([]);

  const fetchTenders = async (tenderType) => {
    const token = localStorage.getItem("k_star_token");
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_URL}/tender/${tenderType}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const tenders = res.data;

      setAllTenders(tenders);
    } catch (error) {
      console.log("Error While fetching tender : ", error);
    } finally {
      setLoading(false); // Set loading to false after the API request is complete (regardless of success or failure)
    }
  };

  useEffect(() => {
    fetchTenders("active");
  }, []);

  return (
    <>
      {loading && (
        <div className="loader-container">
          <div className="loader"></div>
        </div>
      )}
      <div className="body-conetnt-header border-b-2 border-grayone md:sticky md:top-0 bg-white md:z-10">
        <div>
          <h5 className="text-lg leading-8.25 text-blackone font-semibold">
            Open Tenders
          </h5>
        </div>
      </div>
      <div>
        <div className="overflow-auto">
          <Table
            radius="none"
            removeWrapper
            className="custom-table"
            aria-label="Example static collection table"
          >
            <TableHeader>
              <TableColumn></TableColumn>
              <TableColumn>TENDER ID</TableColumn>
              <TableColumn>TENDER NAME</TableColumn>
              <TableColumn>START DATE</TableColumn>
              <TableColumn>END DATE</TableColumn>
              <TableColumn>REMAINING TIME</TableColumn>
              <TableColumn>STATUS</TableColumn>
              <TableColumn></TableColumn>
            </TableHeader>
            <TableBody>
              {allTenders.map((props) => {
                return (
                  <TableRow className="cursor-pointer" key={props._id}>
                    <TableCell className="z-0">
                      <div className="w-4.5">
                        <Dropdown
                          placement="bottom-start"
                          className="select-box"
                          radius="sm"
                          classNames={{
                            content: "p-0 border border-graythree shadow-none ",
                          }}
                        >
                          <DropdownTrigger className="cursor-pointer">
                            <Image
                              alt="Select Icon"
                              className="rounded-none"
                              src="/box-img/blue-box.svg"
                            />
                          </DropdownTrigger>
                        </Dropdown>
                      </div>
                    </TableCell>
                    <TableCell>
                      <div>{props.tenderId}</div>
                    </TableCell>
                    <TableCell>
                      <div>{props.name}</div>
                    </TableCell>
                    <TableCell>
                      <div>
                        {props.startDate &&
                          new Date(props.startDate)
                            .toLocaleString("en-GB", {
                              day: "2-digit",
                              month: "2-digit",
                              year: "numeric",
                              hour: "2-digit",
                              minute: "2-digit",
                              hour12: true,
                            })
                            .replace("am", "AM")
                            .replace("pm", "PM")}
                      </div>
                    </TableCell>
                    <TableCell>
                      <div>
                        {props.endDate &&
                          new Date(props.endDate)
                            .toLocaleString("en-GB", {
                              day: "2-digit",
                              month: "2-digit",
                              year: "numeric",
                              hour: "2-digit",
                              minute: "2-digit",
                              hour12: true,
                            })
                            .replace("am", "AM")
                            .replace("pm", "PM")}
                      </div>
                    </TableCell>
                    <TableCell>
                      <div
                        className={`text-redtwo ${
                          props.remainingTime === "--" ? "ps-14" : ""
                        }`}
                      >
                        {props.remainingTime}
                      </div>
                    </TableCell>
                    <TableCell>
                      {props.status === "ACTIVE" && (
                        <div className="text-blue font-semibold">
                          {props.status}
                        </div>
                      )}
                      {props.status === "EXPIRED" && (
                        <div className="text-redtwo font-semibold">
                          {props.status}
                        </div>
                      )}
                      {props.status === "PENDING" && (
                        <div className="text-orange font-semibold">
                          {props.status}
                        </div>
                      )}
                      {props.status === "REJECTED" && (
                        <div className="text-redtwo font-semibold">
                          {props.status}
                        </div>
                      )}
                    </TableCell>
                    <TableCell>
                      <div className="flex gap-2 justify-end">
                        {!props.userHasBid ? (
                          <Link
                            to={`/open-tender/view-tender/${props._id}`}
                            className="bid_btn"
                          >
                            <Button className="bg-extra-light-green rounded-1.25 lg:py-1.5 py-1">
                              <div className="flex items-center gap-4.5 lg:ps-8 sm:ps-3 lg:pe-4 sm:pe-3">
                                <p className="lg:text-3.75 text-sm font-semibold text-green leading-5.5">
                                  Bid Now
                                </p>
                                <CaretRight
                                  size={16}
                                  color="#008c06"
                                  weight="fill"
                                />
                              </div>
                            </Button>
                          </Link>
                        ) : (
                          <>
                            <Link to={`/open-tender/view-bid/${props._id}`}>
                              <div className="flex items-center z-0 h-full justify-end cursor-pointer">
                                <div className="bg-light-blue rounded-1.25 lg:py-1.5 py-1 lg:px-4 px-3">
                                  <div className="flex items-center gap-4">
                                    <p className="lg:text-3.75 text-sm font-semibold text-dark-blue  leading-5.5">
                                      View Bid
                                    </p>
                                    <CaretDown
                                      size={16}
                                      color="#004f9c"
                                      weight="fill"
                                    />
                                  </div>
                                </div>
                              </div>
                            </Link>
                            <Link to={`/open-tender/edit-tender/${props._id}`}>
                              <div className="flex items-center z-0 h-full justify-end cursor-pointer">
                                <div className="bg-orangeone rounded-1.25 lg:py-1.5 py-1 lg:px-4 px-3">
                                  <div className="flex items-center gap-4">
                                    <p className="lg:text-3.75 text-sm font-semibold text-orange  leading-5.5">
                                      Edit Bid
                                    </p>
                                    <CaretDown
                                      size={16}
                                      color="#FF993B"
                                      weight="fill"
                                    />
                                  </div>
                                </div>
                              </div>
                            </Link>
                          </>
                        )}
                      </div>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </div>
      </div>
    </>
  );
}
