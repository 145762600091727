import React, { useState, useMemo, useEffect, useRef } from "react";
import {
  Chip,
  Table,
  TableHeader,
  TableColumn,
  TableBody,
  TableRow,
  TableCell,
  Input,
  Button,
  Pagination
} from "@nextui-org/react";
import {
  X,
  ArrowLeft,
  CaretDoubleLeft,
  CaretDoubleRight,
  CaretLeft,
  CaretRight,
  CaretDown,
} from "@phosphor-icons/react";
import { Link, useParams, useLocation } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import "./Style.scss";

export default function viewbid() {
  const [customers, setCustomers] = useState([]);
  const [loading, setLoading] = useState(true);
  const { tenderId } = useParams();
  const { pathname } = useLocation()

  const messagesEndRef = useRef(null)

  const fetchTenders = async () => {
    try {
      // Add a check to ensure selectedCustomer is not null
      const token = localStorage.getItem("k_star_token");
      const apiUrl = `${process.env.REACT_APP_API_URL}/auth/getCustomerBidDetailsByTenderId/${tenderId}`;
      const response = await fetch(`${apiUrl}`, {
        headers: {
          Authorization: `Bearer ${token}`, // Use the retrieved token in the headers
        },
      });

      const data = await response.json();

      if (!response.ok) {
        console.error("Error fetching customers:", data);
        // Handle the error, e.g., show an error message to the user
        return;
      }
      // The request was successful (status code 2xx)
      setCustomers(data);

      //   setSelectedCustomer(data);
    } catch (error) {
      console.error("Error fetching customers:", error);
    } finally {
      setLoading(false); // Set loading to false after the API request is complete (regardless of success or failure)
    }
  };
  console.log("FFFFFFF :: ", customers);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredCustomers, setFilteredCustomers] = useState(customers.lots);
  useEffect(() => {
    if (searchTerm.trim() === "") {
      setFilteredCustomers(customers.lots);
    } else {
      // Filter the tenders based on the search term
      console.log("Customer", customers);
      const filtered = customers.lots.filter(
        (customer) =>
          customer?.description
            .toLowerCase()
            .includes(searchTerm.toLowerCase()) ||
          customer?.lot_num.toLowerCase().includes(searchTerm.toLowerCase())
        //customer.mobileNumber.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredCustomers(filtered);
    }
  }, [customers.lots, searchTerm]);

  const updateSearchTerm = (event) => {
    setSearchTerm(event.target.value);
  };
  


  useEffect(() => {
    // Fetch tenders when the component mounts
    fetchTenders("won_bids");
  }, []);

  const getMaxBidValue = (bids) => {
    if (bids.length === 0) {
      return "N/A";
    }

    const maxBid = bids.reduce(
      (max, bid) => (bid.price > max ? bid.price : max),
      0
    );
    return maxBid;
  };

  return (
    <>
      {loading ? (
        <div className="loader-container">
          <div className="loader"></div>
        </div>
      ) : (
        <>
          <div className="body-conetnt-header bg-grayeight">
            <div className=" inline-flex w-full justify-between xl:items-center gap-5  ">
              <div className="flex-grow">
                <div className="flex xl:flex-row flex-col xl:items-center flex-wrap gap-5">
                  <div className="flex items-center gap-10  ">
                    <div className="flex items-center gap-7">
                      {pathname.includes("/open-tender/view-bid") ? (
                        <Link to="/open-tender" className="back_arrow">
                          <ArrowLeft size={24} weight="bold" />
                        </Link>
                      ) : (
                        <Link to="/view-bid" className="back_arrow">
                          <ArrowLeft size={24} weight="bold" />
                        </Link>
                      )}
                      <h5 className="text-xl leading-8.25 text-blackone font-semibold">
                        {customers?.name}
                      </h5>
                    </div>
                  </div>
                  <div className="flex-grow z-0">
                    <div className="flex items-center xl:justify-between flex-wrap gap-5">
                      <div className="order-2 xl:order-1">
                        {customers?.status === "EXPIRED" && (
                          <Chip className="chip text-redtwo bg-light-red">
                            {customers?.status}
                          </Chip>
                        )}
                        {customers?.status === "CLOSED" && (
                          <Chip className="chip text-redtwo bg-light-red">
                            {customers?.status}
                          </Chip>
                        )}
                        {customers?.status === "ACTIVE" && (
                          <Chip className="chip text-green bg-light-green">
                            {customers?.status}
                          </Chip>
                        )}
                        {customers?.status === "INQUEUE" && (
                          <Chip className="chip text-orange bg-light-orange">
                            {customers?.status}
                          </Chip>
                        )}
                      </div>
                      {customers?.status === 'ACTIVE' && <Link to={`/open-tender/edit-tender/${tenderId}`} className="order-1 xl:order-2 z-0 custom_btns">
                        <Button className='bg-orangeone  py-1.5 px-4 '>
                          <div className='flex items-center gap-4.5 px-0'>
                            <p className='text-3.75 font-semibold text-orangetwo leading-5.5'>Edit Bid</p>
                            <CaretDown size={16} color="#FF993B" weight="fill" />
                          </div>
                        </Button>
                      </Link>}
                    </div>
                  </div>
                </div>
              </div>

              {pathname.includes("/open-tender/view-bid") ? (
                <Link to="/open-tender" className="cancle_arrow">
                  <X size={24} color="#7e7e7e" weight="bold" />
                </Link>
              ) : (
                <Link to="/view-bid" className="cancle_arrow">
                  <X size={24} color="#7e7e7e" weight="bold" />
                </Link>
              )}

              {/* <div className='flex-grow z-0'>
                <div className='flex items-center justify-between gap-5'>
                  <div className='order-2 xl:order-1'>
                    <Chip className='chip text-green bg-light-green'>Active</Chip>
                  </div>
                  <div className='order-1 xl:order-2 z-0 custom_btns'>
                    <Button className='bg-orangeone  py-1.5 px-4 ms-3.5'>
                      <div className='flex items-center gap-4.5 px-0'>
                        <p className='text-3.75 font-semibold text-orangetwo leading-5.5'>Edit Bid</p>
                        <CaretDown size={16} color="#FF993B" weight="fill" />
                      </div>
                    </Button>
                  </div>
                </div>
              </div> */}
            </div>
            {customers && (
              <div className="bg-white mt-7.5 border border-mutedeight rounded-2.5">
                <div className="flex items-start tender_details  md:py-4 py-6 px-13">
                  <div className="flex items-start md:justify-between bidtender_info">
                    <div>
                      <span>START DATE</span>
                      <p className="text-darkone ">
                        {customers.startDate &&
                          new Date(customers.startDate)
                            .toLocaleString("en-GB", {
                              day: "2-digit",
                              month: "2-digit",
                              year: "numeric",
                              hour: "2-digit",
                              minute: "2-digit",
                              hour12: true,
                            })
                            .replace("am", "AM")
                            .replace("pm", "PM")}
                      </p>
                    </div>
                    <div>
                      <span>END DATE</span>
                      <p className="text-darkone">
                        {customers.endDate &&
                          new Date(customers.endDate)
                            .toLocaleString("en-GB", {
                              day: "2-digit",
                              month: "2-digit",
                              year: "numeric",
                              hour: "2-digit",
                              minute: "2-digit",
                              hour12: true,
                            })
                            .replace("am", "AM")
                            .replace("pm", "PM")}
                      </p>
                    </div>
                    {/* <div>
                     <span className='whitespace-nowrap'>REMAINING TIME</span>
                     <p className='text-redthree '>10 Hrs 14 Mins</p>
                   </div> */}
                  </div>
                  <div className="bids_texts bidsboxlist">
                    <div>
                      <span className='whitespace-nowrap'>YOUR BIDS</span>
                      <p className='text-darkone font-semibold'>
                        {/* {customers.userBidCount}/{customers.totalLots} */}
                        {customers.lots.filter(bid => bid.userBidValue > 0).length}/{customers.totalLots}
                      </p>
                    </div>
                    <div className="yourbid_texts">
                      <span className="whitespace-nowrap">YOUR BID VALUE</span>
                      <p className="text-dark-blue font-bold">
                        {`$${new Intl.NumberFormat("en-IN").format(
                          customers.lots
                            .reduce((p, c) => {
                              return p + c.userBidValue * c.total_carat;
                            }, 0)
                            .toFixed(2)
                        )}`}
                      </p>
                    </div>
                    <div>
                      <span className="whitespace-nowrap">WON BIDS</span>
                      <p className="text-darkone font-semibold">
                        {customers.countWonBids}
                      </p>
                    </div>
                    <div>
                      <span className="whitespace-nowrap">WON BID VALUE</span>
                      <p className="text-green font-bold">
                        $
                        {new Intl.NumberFormat("en-IN").format(
                          customers?.lots
                            ?.reduce((p, c) => {
                              return c.bidStatus === "won"
                                ? p + c.total_carat * c.userBidValue
                                : p;
                            }, 0)
                            .toFixed(2)
                        )}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {/* //test */}
          </div>
          <div className={` pt-7.5 mt-2.5 relative`}>
            <div className=" md:absolute end-7.5 top-2 md:mx-0 mx-7.5">
              <Input
                className="py-0 md:mb-0 mb-4  search-input sm:w-[397px] w-full "
                // radius="sm"
                clearable
                placeholder="Search"
                classNames={{
                  input: ["bg-transparent", "text-blackone "],
                  innerWrapper: "bg-transparent ",
                  inputWrapper: [
                    "bg-transparent border border border-mutedseven shadow-none py-0",
                    "md:h-11 h-10",
                    "placeholder:text-mutedtwo",
                    "!cursor-text",
                  ],
                }}
                type="text"
                value={searchTerm}
                onChange={updateSearchTerm}
              />
            </div>

            <div>
              <h6 className="text-lg text-dark-blue font-semibold leading-6.25 px-7.5  border-b-2 border-dark-blue inline-block">
                <p className="mb-2.5">Bid Detail</p>
              </h6>
            </div>

            <div ref={messagesEndRef} className="overflow-auto">
              <Table
                radius="none"
                removeWrapper
                className="custom-table border-t-2 border-graytwo"
                aria-label="Example static collection table"
              >
                <TableHeader>
                  <TableColumn>LOT NO.</TableColumn>
                  <TableColumn>DESCRIPTION</TableColumn>
                  <TableColumn>SIZE</TableColumn>
                  <TableColumn>NO. OF STONE</TableColumn>
                  <TableColumn>TOTAL CARAT</TableColumn>
                  <TableColumn>YOUR BID VALUE ($/CT)</TableColumn>
                  <TableColumn>TOTAL VALUE</TableColumn>
                  <TableColumn></TableColumn>
                </TableHeader>
                <TableBody>
                  {filteredCustomers
                    ?.sort((a, b) => a.lot_num.localeCompare(b.lot_num))
                    ?.sort((a, b) => parseInt(a.lot_num) - parseInt(b.lot_num))
                    ?.map((props) => (
                      <TableRow key={props.key}>
                        <TableCell className="">
                          <div>{props.lot_num}</div>
                        </TableCell>
                        <TableCell>
                          <div>{props.description}</div>
                        </TableCell>
                        <TableCell>
                          <div>
                            {new Intl.NumberFormat("en-IN").format(
                              props.size
                            ) || "-"}
                          </div>
                        </TableCell>
                        <TableCell>
                          <div>
                            {new Intl.NumberFormat("en-IN").format(
                              props.no_stone
                            ) || "-"}
                          </div>
                        </TableCell>
                        <TableCell>
                          <div>
                            {new Intl.NumberFormat("en-IN").format(
                              props.total_carat
                            )}
                          </div>
                        </TableCell>
                        <TableCell>
                          <div className="text-blueone font-semibold">
                            {props.userBidValue > 0
                              ? "$" +
                              new Intl.NumberFormat("en-IN").format(
                                props.userBidValue.toFixed(2)
                              )
                              : "-"}
                          </div>
                        </TableCell>
                        <TableCell>
                          <div className="text-blueone font-semibold">
                            {props.total_carat * props.userBidValue
                              ? "$" +
                              new Intl.NumberFormat("en-IN").format(
                                (
                                  props.total_carat * props.userBidValue
                                ).toFixed(2)
                              )
                              : "-"}
                          </div>
                        </TableCell>
                        <TableCell>
                          {props.bidStatus === "pending" && (
                            <p className="font-semibold text-orange custom-width uppercase">
                              {props.bidStatus}
                            </p>
                          )}
                          {props.bidStatus === "won" && (
                            <p className="font-semibold text-green custom-width uppercase">
                              {props.bidStatus}
                            </p>
                          )}
                          {props.bidStatus === "rejected" && (
                            <p className="font-semibold text-redtwo custom-width uppercase">
                              {pathname.includes("/open-tender/view-bid") ? (
                                props.bidStatus
                              ) : (
                                <X size={24} color="#FF0000" weight="bold" />
                              )}
                            </p>
                          )}
                          {props.bidStatus === "closed" && (
                            <p className="font-semibold text-redtwo custom-width uppercase">
                              {pathname.includes("/open-tender/view-bid") ? (
                                props.bidStatus
                              ) : (
                                <X size={24} color="#FF0000" weight="bold" />
                              )}
                            </p>
                          )}
                          {props.bidStatus === "withdraw" && (
                            <p className="font-semibold text-graynine  custom-width uppercase">
                              {pathname.includes("/open-tender/view-bid") ? (
                                props.bidStatus
                              ) : (
                                <X size={24} color="#FF0000" weight="bold" />
                              )}
                            </p>
                          )}
                          {props.bidStatus === "No Bid" && (
                            <p className="font-semibold text-redtwo custom-width uppercase">
                              {pathname.includes("/open-tender/view-bid") ? (
                                props.bidStatus
                              ) : (
                                <X size={24} color="#FF0000" weight="bold" />
                              )}
                            </p>
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </div>
          </div>
        </>
      )}
    </>
  );
}
