import React from 'react'
import { Image, Input, Button } from '@nextui-org/react'
import './Style.scss'

export default function Getotp() {
    return (
        <>
            <div className='login_bg '>
                <div className='grid grid-cols-12 mx-4 login-content'>
                    <div className='lg:col-span-5 col-span-12'>
                        <div className='login_image'>
                            <Image alt='Login Image' src='/login-img/logo.svg' className='ronded-none mx-w-full max-h-full ' />
                        </div>
                    </div>
                    <div className='lg:col-span-7 col-span-12'>
                        <h1 className='text-dark-blue font-bold  text-center'>Login to your Account</h1>
                        <form action='' className='sm:mt-14 mt-8 mx-auto customs_inputs'>


                            <div className='flex items-center justify-center'>
                                <Input
                                    type="number"
                                    label={
                                        <>
                                            <div className='text-dark-blue sm:text-base text-sm font-medium'>Enter OTP</div>
                                        </>
                                    }
                                    placeholder="Enter Your OTP"
                                    labelPlacement="outside"
                                    className='max-w-[550px]'
                                    description={
                                        <>
                                            <p className='text-dark-blue sm:text-base text-sm font-medium text-end'>Resend OTP</p>
                                        </>
                                    }
                                    classNames={{
                                        innerWrapper: "bg-transparent text-dark-blue",
                                        inputWrapper: [
                                            "bg-transparent shadow-none px-4 py-0",
                                            "md:h-12 h-10",
                                            "!cursor-text",
                                        ],

                                    }}

                                />
                            </div>

                            <div className='text-center'>
                                <Button type='submit' className='form_btn'>
                                    SUBMIT
                                </Button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    )
}
