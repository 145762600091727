import axios from "axios";
import React, { useEffect, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";

export const ProtectedRoute = ({ children }) => {
  const navigate = useNavigate();
  const [isTokenValid, setIsTokenValid] = useState(true);

  useEffect(() => {
    const checkTokenValidity = async () => {
      const token = localStorage.getItem("k_star_token");

      try {
        // Make a request to the protected route on the server using Axios
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/auth/getCustomers`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );

        // If the request was successful, no need to check the status code
      } catch (error) {
        console.error("Error checking token validity:", error);

        if (axios.isAxiosError(error) && error.response) {
          const { status } = error.response;

          if (status === 401 || status === 404) {
            // Token expired or invalid, redirect to login page
            setIsTokenValid(false);
            navigate("/login");
          }
        }
      }
    };

    checkTokenValidity();
  }, [navigate]);

  if (!isTokenValid) {
    return <Navigate to="/login" />;
  }

  return children;
};
