import { Button, Image, Input } from "@nextui-org/react";
import { Eye, EyeSlash, Phone } from "@phosphor-icons/react";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./Style.scss";

export default function Login() {
  const [mobileNumber, setMobileNumber] = useState("");
  const [password, setPassword] = useState("");
  const [isVisible, setIsVisible] = React.useState(false);
  const toggleVisibility = () => setIsVisible(!isVisible);
  const navigate = useNavigate();
  const [submitLoading, setSubmitLoading] = useState(false);

  const handleLogin = async (e) => {
    e.preventDefault();

    try {
        setSubmitLoading(true);
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/auth/verifyOTP`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ mobileNumber, password }),
        }
      );

      if (response.ok) {
        const data = await response.json();
        localStorage.setItem("k_star_token", data.token);
        localStorage.setItem("k_star_name", data.username);
        localStorage.setItem("k_star_photo", data.profile_photo);
        localStorage.setItem("k_star_surname", data.surname);
        console.log("Login successful", data.userId);
        setSubmitLoading(false);
        navigate("/dashboard");
      } else {
        const data = await response.json();
        setSubmitLoading(false);
        toast.error(data?.message);
      }
    } catch (error) {
      console.log("respons22e", error);
      console.error("Error during login:", error);
      toast.error("Error during Login");
    }
  };

  return (
    <>
      <div className="login_bg ">
        <div className="grid grid-cols-12 mx-4 login-content">
          <div className="lg:col-span-5 col-span-12 ">
            <div className="login_image sm:flex-row flex-col lg:gap-0 gap-5">
              <Image
                alt="Login Image"
                src="/login-img/logo.svg"
                className="ronded-none mx-w-full max-h-full hidden lg:block  "
              />
              <div className="w-full sm:w-auto">
                <img
                  alt="Login Image"
                  src="/navbar-img/logo.png"
                  className="ronded-none mx-w-full mx-auto max-h-full lg:hidden  "
                />
              </div>
              <h2 className="text-dark-blue font-bold sm:text-5xl text-4xl lg:hidden whitespace-nowrap">
                K. STAR EXPORT
              </h2>
            </div>
          </div>
          <div className="lg:col-span-7 col-span-12">
            <h1 className="text-dark-blue font-bold text-center">
              Customer Panel
            </h1>
            <h4 className="text-dark-blue font-normal pt-2 text-center sm:text-2xl text-xl">
              Login to your account
            </h4>
            <form action="" className="sm:mt-14 mt-8 mx-auto customs_inputs">
              <div className="flex items-center justify-center">
                <Input
                  type="tel"
                  label={
                    <>
                      <div className="uppercase text-dark-blue sm:text-base text-sm font-medium">
                        MOBILE NUMBER
                      </div>
                    </>
                  }
                  placeholder="Enter Your Mobile Number"
                  labelPlacement="outside"
                  className="max-w-[550px]"
                  value={mobileNumber}
                  onChange={(e) => setMobileNumber(e.target.value)}
                  classNames={{
                    innerWrapper: "bg-transparent text-dark-blue",
                    inputWrapper: [
                      "bg-transparent shadow-none px-4 py-0",
                      "md:h-12 h-10",
                      "!cursor-text",
                    ],
                  }}
                  endContent={<Phone size={24} color="#71717A" />}
                />
              </div>

              <div className="flex items-center justify-center">
                <Input
                  label={
                    <>
                      {/* <div className='uppercase text-dark-blue sm:text-base text-sm font-medium'>ENTER OTP</div> */}
                      <div className="uppercase text-dark-blue sm:text-base text-sm font-medium">
                        ENTER Password
                      </div>
                    </>
                  }
                  placeholder="Enter Password"
                  labelPlacement="outside"
                  value={password}
                  className="max-w-[550px]"
                  onChange={(e) => setPassword(e.target.value)}
                  classNames={{
                    innerWrapper: "bg-transparent text-dark-blue",
                    inputWrapper: [
                      "bg-transparent shadow-none px-4 py-0",
                      "md:h-12 h-10",
                      "!cursor-text",
                    ],
                  }}
                  endContent={
                    <button
                      className="focus:outline-none"
                      type="button"
                      onClick={toggleVisibility}
                    >
                      {isVisible ? (
                        <Eye size={24} color="#71717A" />
                      ) : (
                        <EyeSlash size={24} color="#71717A" />
                      )}
                    </button>
                  }
                  type={isVisible ? "text" : "password"}
                />
              </div>
              <div className="text-center">
                <Button
                  type="submit"
                  className="form_btn"
                  onClick={handleLogin}
                  isDisabled={submitLoading}
                >
                  <div className="flex items-center justify-center">
                    <div>Login </div>
                    {submitLoading && (
                      <div className="small-loader extra-small-loader ms-4"></div>
                    )}
                  </div>
                </Button>
              </div>

              <div className="flex items-center justify-center gap-0.75 ">
                <div className="md:text-4.25 text-3.75 text-dark-blue">
                  New User?
                </div>
                <Link
                  to="/register"
                  className="md:text-4.25 text-3.75 font-semibold text-dark-blue"
                >
                  Register Here
                </Link>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
