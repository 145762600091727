import {
  Button,
  Chip,
  Input,
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
} from "@nextui-org/react";
import { ArrowLeft, X } from "@phosphor-icons/react";
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./Style.scss";

export default function Edittender() {
  const [tender, setTender] = useState({});
  const [lotBids, setLotBids] = useState([]);
  const [totalBids, setTotalBids] = useState();
  const [loading, setLoading] = useState(true);
  const [submitLoading, setSubmitLoading] = useState(false);

  const navigate = useNavigate();
  const handleCancelClick = () => {
    navigate("/open-tender");
  };


  const { tenderId } = useParams();
  const [totalLotBid, setTotalLotBid] = useState(0);
  const handleBidChange = (lotId, bidValue, carat) => {
    const bidValueNumeric = bidValue !== "" ? bidValue : '';
    const caratNumeric = parseFloat(carat); // Assuming carat is always a valid number
    
    setLotBids((prevLotBids) => {
      const updatedLotBids = prevLotBids.map((bid) =>
      
        
        bid.lotId === lotId ? { ...bid, price: bidValueNumeric } : bid
        
      
      );

      // Recalculate the total bid value for all lots, ignoring null or empty strings
      const newTotalBidValue = updatedLotBids.reduce((total, currentBid) => {
        // Only add to total if price is not null and is a number
        const bidAmount =
          currentBid.price !== '' && !isNaN(currentBid.price)
            ? parseFloat(currentBid.price) * caratNumeric : 0;
            
        return total + bidAmount;
      }, 0);

      // Update the total bid value state
      setTotalLotBid(newTotalBidValue);

      return updatedLotBids;
    });
  };

  const fetchData = async () => {
    const token = localStorage.getItem("k_star_token");
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_URL}/tender/lots/${tenderId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setTender(res.data);
      const initialBids = res.data.lots.map((lot) => {
        const userBid = lot.bids.find((b) => b.userId === lot.userId);
        return {
          lotId: lot._id,
          price: userBid ? parseFloat(userBid.price) || '' : '',
          carat: parseFloat(lot.total_carat),
          description: lot.description,
          lot_num: lot.lot_num,
          size: lot.size,
          no_stone: lot.no_stone,
          status: lot.status,
        };
      });

      setLotBids(initialBids);


    } catch (error) {
      console.log("Error While Fetching lots data : ", error);
    } finally {
      setLoading(false);
    }
  };

  const editBid = async () => {
    try {
      setSubmitLoading(true);
      const token = localStorage.getItem("k_star_token");
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/tender/lots/:lotId/bids`,
        lotBids,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (tender?.lots?.length < totalBids) {
        setTotalBids(totalBids + 1);
      }
      fetchData();
      toast.success("Bid updated successfully!");
      navigate("/open-tender");
      // console.log(res);
    } catch (error) {
      console.log("Error while updating Bids : ", error);
      toast.error("Error updating bid. Please try again.");
    } finally {
      setSubmitLoading(false); // Stop loading
    }
  };

  useEffect(() => {
    fetchData();
  }, [tenderId]);
  useEffect(() => {
    const total = lotBids.reduce((acc, bid) => {
      return bid.price > 0? acc + (parseFloat(bid.price) || 0) * (parseFloat(bid.carat) || 0) : acc;
    }, 0);
    setTotalLotBid(total);
  }, [lotBids]);
  const inputRefs = useRef([]);
 

  const focusNextInput = (index, isShiftPressed) => {
    const nextIndex = isShiftPressed ? index - 1 : index + 1;
    if (nextIndex >= 0 && nextIndex < inputRefs.current.length) {
      inputRefs.current[nextIndex].focus();

      // Scroll to the top of the focused input
      const inputTop = inputRefs.current[nextIndex].getBoundingClientRect().top;
      const offset = window.scrollY + inputTop - 16; // Adjust the offset if needed
      window.scrollTo({ top: offset, behavior: "smooth" });
    }
  };

  return (
    <>
      {loading ? (
        <div className="loader-container">
          <div className="loader"></div>
        </div>
      ) : (
        <>
          <div className="body-conetnt-header bg-grayeight">
            <div className="inline-flex w-full justify-between gap-5  ">
              <div className="flex-grow">
                <div className="flex xl:flex-row flex-col xl:items-center flex-wrap gap-5">
                  <div className="flex items-center gap-10  ">
                    <div className="flex items-center gap-7">
                      <Link to="/open-tender" className="back_arrow">
                        <ArrowLeft size={24} weight="bold" />
                      </Link>
                      <h5 className="text-xl leading-8.25 text-blackone font-semibold ">
                        {tender?.name}
                      </h5>
                    </div>
                  </div>
                  <div className="flex-grow z-0">
                    <div>
                      <Chip className="chip text-green bg-light-green">
                        {tender?.status}
                      </Chip>
                    </div>
                  </div>
                </div>
              </div>
              <Link to="/open-tender" className="cancle_arrow">
                <X size={24} color="#7e7e7e" weight="bold" />
              </Link>
            </div>
            <div className="bg-white mt-7.5 border border-mutedeight rounded-2.5">
              <div className="flex items-start tender_details md:py-4 py-6 px-13">
                <div className="flex items-start md:justify-between tender_info">
                  <div>
                    <span className="whitespace-nowrap">START DATE</span>
                    <p className="text-darkone ">
                      {tender.startDate &&
                        new Date(tender.startDate)
                          .toLocaleString("en-GB", {
                            day: "2-digit",
                            month: "2-digit",
                            year: "numeric",
                            hour: "2-digit",
                            minute: "2-digit",
                            hour12: true,
                          })
                          .replace("am", "AM")
                          .replace("pm", "PM")}
                    </p>
                  </div>
                  <div>
                    <span className="whitespace-nowrap">END DATE</span>
                    <p className="text-darkone ">
                      {tender.endDate &&
                        new Date(tender.endDate)
                          .toLocaleString("en-GB", {
                            day: "2-digit",
                            month: "2-digit",
                            year: "numeric",
                            hour: "2-digit",
                            minute: "2-digit",
                            hour12: true,
                          })
                          .replace("am", "AM")
                          .replace("pm", "PM")}
                    </p>
                  </div>
                  <div>
                    <span className="whitespace-nowrap">REMAINING TIME</span>
                    <p className="text-redthree ">{tender.remainingTime}</p>
                  </div>
                </div>
                {totalBids && totalBids >= 0 ? (
                  <div className="bids_texts">
                    <span className="whitespace-nowrap">YOUR BIDS</span>
                    <p className="text-darkone  font-semibold">{`${totalBids}/${tender?.lots?.length}`}</p>
                  </div>
                ) : null}
                <div>
                  <div className="total_value">
                    <span className="whitespace-nowrap">TOTAL BID VALUE</span>
                    <p className="text-dark-blue font-bold">
                      {`$${totalLotBid.toFixed(2)}  `}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="pt-7.5 pb-24 mt-2.5">
            <h6 className="text-lg text-dark-blue font-semibold leading-6.25 px-7.5  border-b-2 border-dark-blue inline-block">
              <p className="mb-2.5">Edit Bid Detail</p>
            </h6>
            <div className="overflow-auto">
              <Table
                radius="none"
                removeWrapper
                className="custom-table border-t-2 border-graytwo"
                aria-label="Example static collection table"
              >
                <TableHeader>
                  <TableColumn>LOT NO.</TableColumn>
                  <TableColumn>DESCRIPTION</TableColumn>
                  <TableColumn>SIZE</TableColumn>
                  <TableColumn>NO. OF STONE</TableColumn>
                  <TableColumn>TOTAL CARAT</TableColumn>
                  <TableColumn>BID VALUE ($/CT)</TableColumn>
                  <TableColumn>TOTAL VALUE</TableColumn>
                </TableHeader>

                <TableBody>
                  {lotBids
                    ?.sort((a, b) => a.lot_num.localeCompare(b.lot_num))
                    ?.sort((a, b) => parseInt(a.lot_num) - parseInt(b.lot_num))
                    ?.map((bid, index) => (
                      <TableRow key={bid.lotId}>
                        {/* ... other cells ... */}
                        <TableCell className="">
                          <div>{bid.lot_num}</div>
                        </TableCell>
                        <TableCell>
                          <div>{bid.description}</div>
                        </TableCell>
                        <TableCell>
                          <div>{bid.size || "-"}</div>
                        </TableCell>
                        <TableCell>
                          <div>{bid.no_stone || "-"}</div>
                        </TableCell>
                        <TableCell>
                          <div>{bid.carat}</div>
                        </TableCell>
                        <TableCell className="inputs_box z-0">
                          <Input
                            value={bid.price !== ''? bid.price : ""}
                            ref={(el) => (inputRefs.current[index] = el)}
                            onKeyDown={(e) => {
                              if (e.key === "Tab" && !e.shiftKey) {
                                e.preventDefault(); // Prevent default Tab behavior
                                focusNextInput(index, false); // Focus next input
                              } else if (e.key === "Tab" && e.shiftKey) {
                                e.preventDefault(); // Prevent default Shift + Tab behavior
                                focusNextInput(index, true); // Focus previous input
                              }
                            }}
                            className="py-0 tender-name custom-box z-0"
                            isClearable
                            classNames={{
                              input: ["bg-transparent", "text-blackone","z-0"],
                              innerWrapper: "bg-transparent z-0",
                              inputWrapper: [
                                "bg-transparent border border border-mutedseven  shadow-none py-0 z-0",
                                "md:h-11 h-10",
                                "!cursor-text",
                              ],
                            }}
                            type="text"
                            startContent={
                              <div>
                                <span className="">$</span>
                              </div>
                            }
                            disabled={
                              tender?.status === "ACTIVE" &&
                              bid.status === "pending"
                                ? false
                                : true
                            }
                            onChange={(e) =>
                              handleBidChange(
                                bid.lotId,
                                e.target.value,
                                bid.carat
                              )
                            }
                          />
                          {tender?.status !== "ACTIVE" ||
                          bid.status !== "pending" ? (
                            <div className="text-xs text-darkred mb-1">
                              Bidding is not available for this Lot.
                            </div>
                          ) : null}
                        </TableCell>
                        <TableCell>
                          <div className="text-blueone font-semibold">
                            {bid.price > 0 && bid.carat
                              ? `$${(
                                  parseFloat(bid.price) * parseFloat(bid.carat)
                                ).toFixed(2)}`
                              : "-"}
                          </div>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </div>

            <div className="tender-foot">
              <div className="lg:px-7.5 px-5 py-5.5 flex items-center justify-end gap-4">
                <Button
                  className="cancletender_btn"
                  onClick={handleCancelClick}
                >
                  <p>CANCEL</p>
                </Button>
                <Button
                  className="savetender_btn"
                  onClick={editBid}
                  isDisabled={tender?.status !== "ACTIVE" || submitLoading}
                >
                  <div className="flex items-center justify-center">
                    <div>SUBMIT </div>
                    {submitLoading && (
                      <div className="small-loader extra-small-loader ms-4"></div>
                    )}
                  </div>
                </Button>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}
