import React, { useState } from 'react';
import { Image } from "@nextui-org/react";
import { Link, useLocation } from 'react-router-dom';
import './Sidebar.scss'

export default function Sidebar({ toggleSidebar }) {

    // const location = useLocation();

    // const isActive = (path) => {
    //     return location.pathname === path;
    // };

    const loc = useLocation();
    const openTenderRegex = /^\/open-tender(?:\/.*)?$/;
    const wonbidsRegex = /^\/won-bids(?:\/.*)?$/;
    const bidshistoryRegex = /^\/view-bid(?:\/.*)?$/;
    // Test the pathname and determine the class name

    const dashboardbg = loc.pathname === '/dashboard' ? 'active-link' : 'defaultText';
    const opentenderbg = openTenderRegex.test(loc.pathname) ? 'active-link' : 'defaultText';
    const wonbidsbg = wonbidsRegex.test(loc.pathname) ? 'active-link' : 'defaultText';
    const bidshistorybg = bidshistoryRegex.test(loc.pathname) ? 'active-link' : 'defaultText';


    return (
        <>
            <aside className='bg-dark-blue sidebar overflow-auto z-20'>
                <div className="flex flex-col gap-3">
                    <Link to="/dashboard" className={`flex items-center gap-3.5.25 ps-7 hover:opacity-100 py-4 custom-hov ${dashboardbg} `} onClick={toggleSidebar}>
                        <div><Image alt="Dashboard Image Icon" src="/sidebar-img/dashboard.svg" className="rounded-none" /></div>
                        <p className='text-base leading-5.5 text-light-white'>Dashboard</p>
                    </Link>
                    <div className='flex flex-col gap-2'>
                        <Link to="#" className="flex items-center gap-3.5.25 ps-7 hover:opacity-100 py-4 cursor-default" onClick={toggleSidebar}>
                            <div><Image alt="Tenders Image Icon" src="/sidebar-img/tenders.svg" className="rounded-none" /></div>
                            <p className='text-base leading-5.5 text-light-white'>Tenders</p>
                        </Link>
                        <ul className='nav-list'>
                            <li className={`custom-hov ${opentenderbg}`} onClick={toggleSidebar}>
                                <Link to="/open-tender" className="w-full text-sm text-light-white  font-normal" >
                                    Open Tenders
                                </Link>
                            </li>
                            <li className={`custom-hov ${wonbidsbg}`} onClick={toggleSidebar}>
                                <Link to="/won-bids" className="w-full text-sm text-light-white  font-normal" >
                                    Won Bids
                                </Link>
                            </li>
                            <li className={`custom-hov ${bidshistorybg}`} onClick={toggleSidebar}>
                                <Link to="/view-bid" className="w-full text-sm text-light-white  font-normal">
                                    Bid History
                                </Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </aside >
        </>
    )
}
