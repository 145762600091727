import React, { useState, useMemo, useEffect, useRef } from "react";
import {
  Input,
  Image,
  Table,
  TableHeader,
  TableColumn,
  TableBody,
  TableRow,
  TableCell,
  Pagination
} from "@nextui-org/react";
import {
  CaretDown,
  CaretDoubleLeft,
  CaretDoubleRight,
  CaretLeft,
  CaretRight,
} from "@phosphor-icons/react";
import { Link, useNavigate } from "react-router-dom";
import { open_birds } from "./Data";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function Wonbids() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const viewbids = (tenderId) => {
    navigate(`/won-bids/view-won-bids/${tenderId}`);
  };
  const [customers, setCustomers] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredTenders, setFilteredTenders] = useState(customers);

  const messagesEndRef = useRef(null)

  useEffect(() => {
    if (searchTerm.trim() === "") {
      setFilteredTenders(customers);
    } else {
      // Filter the tenders based on the search term
      const filtered = customers.filter(
        (tender) =>
          tender.tenderId.toLowerCase().includes(searchTerm.toLowerCase()) ||
          tender.name.toLowerCase().includes(searchTerm.toLowerCase())
      );

      setFilteredTenders(filtered);
    }
  }, [customers, searchTerm]);

  const updateSearchTerm = (event) => {
    setSearchTerm(event.target.value);
  };
  console.log("bhbhbhbhbh : ", localStorage.getItem('page'));
  const pageNum = localStorage.getItem('page');
  const pageRow = localStorage.getItem('pageRow')
  // Table Pagination
  const [page, setPage] = useState(parseInt(pageNum) || 1);
  const [rowsPerPage, setRowsPerPage] = useState(pageRow || 50);

  const pages = Math.ceil(filteredTenders.length / rowsPerPage);
  const items = useMemo(() => {
    const start = (page - 1) * rowsPerPage;
    const end = start + rowsPerPage;
    return filteredTenders.slice(start, end);
  }, [page, rowsPerPage]);

  useEffect(() => {
    console.log("Use State : ", page);
    localStorage.setItem('page', page && page != 1 ? page : 1);


  }, [page])
  useEffect(() => {
    
    console.log("Use State Row Per Pages : ", rowsPerPage);
    localStorage.setItem('pageRow', rowsPerPage && rowsPerPage != 50 ? rowsPerPage : 50);
   
  }, [rowsPerPage])

  const handlePageSizeChange = (e) => {
    setRowsPerPage(Number(e.target.value));
    setPage(1);
  };
  const handlePrevPage = () => {
    setPage((currentPage) => Math.max(currentPage - 1, 1));
    messagesEndRef?.current?.scrollIntoView({ behavior: "smooth" })
  };
  const handleNextPage = () => {
    setPage((currentPage) => Math.min(currentPage + 1, pages));
    messagesEndRef?.current?.scrollIntoView({ behavior: "smooth" })
  };
  const handleFirstPage = () => {
    setPage(1);
    messagesEndRef?.current?.scrollIntoView({ behavior: "smooth" })
  };
  const handleLastPage = () => {
    setPage(pages);
    messagesEndRef?.current?.scrollIntoView({ behavior: "smooth" })
  };

  // useEffect(() => {
  //   setPage(1);
  // }, [rowsPerPage]);
  const startIndex = (page - 1) * rowsPerPage;
  const endIndex = page * rowsPerPage;
  const wonbidsShow = filteredTenders.slice(startIndex, endIndex);


  const fetchTenders = async (status) => {
    try {
      // Add a check to ensure selectedCustomer is not null
      const token = localStorage.getItem("k_star_token");
      const apiUrl = `${process.env.REACT_APP_API_URL}/auth/getCustomerWonBid?status=${status}`;
      const response = await fetch(`${apiUrl}`, {
        headers: {
          Authorization: `Bearer ${token}`, // Use the retrieved token in the headers
        },
      });

      const data = await response.json();

      if (!response.ok) {
        console.error("Error fetching customers:", data);
        // Handle the error, e.g., show an error message to the user
        return;
      }

      // The request was successful (status code 2xx)
      setCustomers(data);

      //setSelectedCustomer(data);
    } catch (error) {
      console.error("Error fetching customers:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    // Fetch tenders when the component mounts
    fetchTenders("won_bids");
  }, []);
  return (
    <>
      {loading && (
        <div className="loader-container">
          <div className="loader"></div>
        </div>
      )}
      <div className="body-conetnt-header border-b-2 md:sticky md:top-0 bg-white md:z-10 border-grayone">
        <div className="flex  flex-wrap items-center justify-between gap-5 ">
          <h5 className="text-lg leading-8.25 text-blackone font-semibold">
            Won Bids
          </h5>
          <div className="z-0">
            <Input
              classNames={{
                input: [
                  "placeholder:text-xs placeholder:text-mutedone text-mutedone leading-4 p-0 outline-none",
                ],
                inputWrapper: [
                  "bg-transparent shadow-none border border-graythree h-9.5 min-w-72.5 !cursor-text py-0 px-4.5",
                ],
              }}
              placeholder="Search by Tender ID, Name..."
              startContent={
                <Image
                  alt="Image Search Icon"
                  src="/header-img/icons/search.svg"
                />
              }
              value={searchTerm}
              onChange={updateSearchTerm}
            />
          </div>
        </div>
      </div>

      <div className="flex-grow ">
        <div ref={messagesEndRef} className="tablefix">
          <Table
            radius="none"
            removeWrapper
            className="custom-table"
            classNames={{
              base: "sm:max-h-full sm:overflow-scroll",
              table: "sm:min-h-full",
            }}
          >
            <TableHeader>
              <TableColumn>TENDER ID</TableColumn>
              <TableColumn>TENDER NAME</TableColumn>
              <TableColumn>YOUR BID LOT</TableColumn>
              <TableColumn>TOTAL VALUE</TableColumn>
              <TableColumn>STATUS</TableColumn>
              <TableColumn></TableColumn>
            </TableHeader>

            <TableBody>
              {wonbidsShow &&
                wonbidsShow?.length &&
                wonbidsShow?.map((lot, index) => (
                  <TableRow className=" cursor-pointer" onClick={() => viewbids(lot._id)} key={lot._id}>
                    <TableCell className="">
                      <div>{lot.tenderId}</div>
                    </TableCell>
                    <TableCell>
                      <div>{lot.name}</div>
                    </TableCell>

                    <TableCell>
                      <div>
                        {lot.userBidCount}/{lot.totalLots}
                      </div>
                    </TableCell>
                    <TableCell>
                      <div>{parseFloat(lot.lots.reduce((p, c) => {
                        return c.userBidValue ? p + c.userBidValue * c.total_carat : p
                      }, 0)).toFixed(2)}</div>
                    </TableCell>

                    <TableCell>
                      {lot.status === 'EXPIRED' && <div className="text-redtwo font-semibold capitalize">{lot.status}</div>}
                      {lot.status === 'ACTIVE' && <div className="text-blue font-semibold capitalize">{lot.status}</div>}
                      {lot.status === 'CLOSED' && <div className="text-redtwo font-semibold capitalize">{lot.status}</div>}
                      {lot.status === 'PENDING' && <div className="text-orange font-semibold capitalize">{lot.status}</div>}
                      {/* <div className="text-green font-semibold">{lot.status}</div> */}
                    </TableCell>
                    <TableCell className="z-0">
                      <div className="flex items-center z-0 h-full justify-end cursor-pointer">
                        <div
                          className="bg-light-blue rounded-2.5 lg:py-1.5 py-1 lg:px-4 px-3"
                          onClick={() => viewbids(lot._id)}
                        >
                          <div className="flex items-center gap-4">
                            <p className="lg:text-3.75 text-sm font-semibold text-dark-blue  leading-5.5">
                              View
                            </p>
                            <CaretDown
                              size={16}
                              color="#004f9c"
                              weight="fill"
                            />
                          </div>
                        </div>
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </div>


        {/* Pagination */}
        {wonbidsShow && wonbidsShow.length ? <div className="lg:px-7.5 px-5 paginations">
          <div className="flex flex-wrap justify-center sm:justify-end items-center gap-5 sm:gap-14 py-5.5">
            <div className="flex items-center gap-2">
              <p className="text-darkone text-sm leading-4.5.25 font-medium">
                Page size
              </p>
              <select
                className="focus:outline-none font-medium text-sm text-darkone w-12 cursor-pointer"
                onChange={handlePageSizeChange}
                value={rowsPerPage}
              >

                <option value="50">50</option>
                <option value="75">75</option>
                <option value="100">100</option>
                <option value="150">150</option>
              </select>
            </div>
            <Pagination
              // isCompact
              showControls
              total={pages || 1}
              // total={10}
              page={page}
              // color="warning"
              classNames={{
                wrapper: "rounded ",
                item: "rounded ",
                cursor:
                  "bg-dark-blue  text-white rounded",
              }}

              initialPage={1}
              onChange={(newPage) => {
                setPage(newPage);
                messagesEndRef?.current?.scrollIntoView({ behavior: "smooth" });
              }}
            />
          </div>
        </div> :null}
      </div>

    </>
  );
}
