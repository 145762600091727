import React, { useState, useRef } from "react";
import { Image, Input, Button, image } from "@nextui-org/react";
import { Link, useNavigate } from "react-router-dom";
import { Eye, EyeSlash } from "@phosphor-icons/react";
import axios from "axios";
import { useFormik } from "formik";
import * as Yup from "yup";
import "./Style.scss";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import ReactCrop, { centerCrop, makeAspectCrop } from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";

export default function Register() {
  const [fileName, setFileName] = useState("");
  const [fileError, setFileError] = useState("");
  const [imagePreview, setImagePreview] = useState("");
  const navigate = useNavigate();
  const [isVisible, setIsVisible] = React.useState(false);
  const toggleVisibility = () => setIsVisible(!isVisible);
  const [submitLoading, setSubmitLoading] = useState(false);

  const [isCropping, setIsCropping] = useState(false);
  const [x, setX] = useState("");

  const [crop, setCrop] = useState({
    aspect: 1,
  });
  const [croppedImageUrl, setCroppedImageUrl] = useState(null);
  const imageRef = useRef();
  function centerAspectCrop(
    mediaWidth,
    mediaHeight,
    aspect,
  ) {
    return centerCrop(
      makeAspectCrop(
        {
          unit: '%',
          width: 90,
        },
        aspect,
        mediaWidth,
        mediaHeight,
      ),
      mediaWidth,
      mediaHeight,
    )
  }
  const onImageLoaded = (e) => {

    const { width, height } = e.target

    setCrop(centerAspectCrop(width, height, 1))
    imageRef.current = e.target;

  };
  // function onImageLoad(e) {
  //   console.log("jjiwoueuyegu : ",e)
  //   if (true) {
  //     const { width, height } = e.target
  //     setCrop({width: width, height: height, aspect: 1})
  //   }
  // }
  const onCropChange = (_, crop) => {
    // You can use either the pixel crop or the percentCrop

    setIsCropping(true);

    setCrop(crop);

    console.log("On Crop Change : ", crop);
  };

  const onCropComplete = async (c) => {
    console.log("On Crop Complete : ", crop);
    if (imageRef.current && c.width && c.height) {
      const croppedImageUrl = await getCroppedImg(
        imageRef.current,
        c,
        "newFile.jpeg"
      );
      setCroppedImageUrl(croppedImageUrl);
    }
  };

  const getCroppedImg = (image, c, fileName) => {
    // console.log("bchujsabvcjhsd : ", image);
    const canvas = document.createElement("canvas");

    const ctx = canvas.getContext("2d");

    console.log("NaturalW : ", image.naturalWidth);
    console.log("NaturalH : ", image.naturalHeight);
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;

    console.log("scaleX : ", scaleX);
    console.log("scaleY : ", scaleY);

    console.log("bchujsabvcjhsd : ", c);


    if (c.unit !== "px") {
      console.log("Crop Unit : ", c.unit);
      const pixelCrop = {
        x: (c.x / 100) * image.width,
        y: (c.y / 100) * image.height,
        width: (c.width / 100) * image.width,
        height: (c.height / 100) * image.height,
      };

      canvas.width = pixelCrop.width;
      canvas.height = pixelCrop.height;

      ctx.drawImage(
        image,
        pixelCrop.x * scaleX,
        pixelCrop.y * scaleY,
        pixelCrop.width * scaleX,
        pixelCrop.height * scaleY,
        0,
        0,
        canvas.width,
        canvas.height
      );
      console.log("pixelCrop.width : ", pixelCrop.width);
      console.log("pixelCrop.height : ", pixelCrop.height);
    }

    console.log("Crop.width : ", c.width);
    console.log("Crop.height : ", c.height);
    console.log("image.width : ", image.width);
    console.log("image.width : ", image.width);
    console.log("pixelCrop.X : ", c.x);
    console.log("pixelCrop.Y : ", c.y);

    //

    canvas.width = c.width;
    canvas.height = c.height;

    ctx.drawImage(
      image,
      c.x * scaleX,
      c.y * scaleY,
      c.width * scaleX,
      c.height * scaleY,
      0,
      0,
      canvas.width,
      canvas.height
    );
    let fileUrl = "";
    return new Promise((resolve, reject) => {
      canvas.toBlob((blob) => {
        if (!blob) {
          //reject(new Error('Canvas is empty'));
          console.error("Canvas is empty");
          return;
        }
        console.log("Blob Size : ", blob);
        blob.name = fileName;
        URL.revokeObjectURL(fileUrl);
        fileUrl = URL.createObjectURL(blob);
        console.log("File Url : ", fileUrl);
        const imageElement = document.createElement("img");
        imageElement.src = fileUrl;
        //console.log("File Url : ", fileUrl);
        const blobUrl = URL.createObjectURL(blob);

        console.log("Blob Url : ", blobUrl);
        setX(blob);
        resolve(fileUrl);
      }, "image/jpeg");
    });
  };

  const formik = useFormik({
    initialValues: {
      customerName: "",
      companyName: "",
      fatherName: "",
      surname: "",
      mobileNumber: "",
      villageName: "",
      agentName: "",
      agentMobile: "",
      profile_photo: "",
      password: "",
    },
    validationSchema: Yup.object({
      customerName: Yup.string().required("Customer name is required"),
      fatherName: Yup.string().required("Father name is required"),
      surname: Yup.string().required("Surname is required"),
      mobileNumber: Yup.string()
        .required("Mobile number is required")
        .matches(/^\d{10}$/, "Must be a 10-digit valid number"),
      villageName: Yup.string().required("Village name is required"),
      // agentName: Yup.string().required("Agent name is required"),
      agentMobile: Yup.string()
        .matches(/^\d{10}$/, "Must be a 10-digit valid number"),
      password: Yup.string()
        .required("Password is required")
        .min(6, "Password must be at least 6 characters"),
      // profile_photo: Yup.string().required("Profile picture is required"),
    }),
    onSubmit: async (values) => {
      try {
        // Create FormData for sending files
        setSubmitLoading(true);
        const formData = new FormData();
        if (croppedImageUrl) {
          const response = await fetch(croppedImageUrl);
          console.log("RESPONSE : ", response);
          const blob = await response.blob();

          formData.append("profile_photo", x);
        }
        //  else {
        //   // Fallback to the original file if for some reason the cropped image is not available
        //   formData.append(
        //     "profile_photo",
        //     document.querySelector("input[type=file]").files[0]
        //   );
        // }
        // Append other customer data
        formData.append("customerName", values.customerName);
        formData.append("companyName", values.companyName);
        formData.append("fatherName", values.fatherName);
        formData.append("surname", values.surname);
        formData.append("mobileNumber", values.mobileNumber);
        formData.append("villageName", values.villageName);
        formData.append("agentName", values.agentName);
        formData.append("agentMobile", values.agentMobile);
        formData.append("password", values.password);

        // Make the API call
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/auth/register`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        toast.success("User is successfully registered");
        setSubmitLoading(false);
        navigate("/login");
      } catch (error) {
        setSubmitLoading(false);
        toast.error(error?.response?.data?.message);
      }
    },
  });

  const handleFileChange = (e) => {
    const fileInput = document.querySelector("input[type=file]");
    if (fileInput && fileInput.files.length > 0) {
      const file = fileInput.files[0];
      if (file) {
        // Check if the file size is less than or equal to 5MB
        const maxSizeInBytes = 5 * 1024 * 1024; // 5MB
        if (file.size > maxSizeInBytes) {
          setFileError("File size exceeds 5MB. Please choose a smaller file.");
          fileInput.value = ""; // Clear the input
          setFileName("");
          setImagePreview("");
        } else {
          // Check if the file type is allowed
          const allowedFileTypes = ["image/png", "image/jpeg", "image/jpg"];
          if (!allowedFileTypes.includes(file.type)) {
            setFileError("Only PNG, JPG, and JPEG files are allowed.");
            fileInput.value = ""; // Clear the input
            setFileName("");
            setImagePreview("");
          } else {
            setFileError(""); // Clear previous error message
            setFileName(file.name);

            // Read the file content as a data URL
            const reader = new FileReader();
            reader.onloadend = () => {
              setIsCropping(true);
              setImagePreview(reader.result);
              setCrop({ aspect: 1 });
            };
            reader.readAsDataURL(file);
          }
        }
      } else {
        setFileError("Invalid file selected. Please choose a valid file.");
        setFileName("");
        setImagePreview("");
      }
    } else {
      setFileError(""); // Clear previous error message
      setFileName("");
      setImagePreview("");
    }
    e.target.value = null
  };

  const applyCrop = async () => {
    // Do something with the cropped image, such as saving it or displaying it
    // For example:
    const croppedImageUrl = await getCroppedImg(
      imageRef.current,
      crop,
      "cropped-image.jpeg"
    );
    console.log("Ceropped Image Url :", croppedImageUrl);
    setImagePreview(croppedImageUrl);
    setIsCropping(false);
  };

  const cancelCrop = () => {
    // Reset the crop state or perform any other necessary actions
    setCrop({ aspect: 1 });
    setCroppedImageUrl("");
    setImagePreview("");
    setIsCropping(false);
  };

  return (
    <>
      {isCropping && imagePreview && (
        <div className="backdrops fixed top-0 start-0 end-0 bottom-0 z-[22] flex items-center justify-center">
          <div className="bg-white rounded-md sm:w-[500px] w-full sm:mx-6 mx-4 border border-mutedseven">
            <ReactCrop
              src={imagePreview}
              crop={crop}
              ruleOfThirds
              //onImageLoaded={onImageLoaded}
              onComplete={onCropComplete}
              onChange={onCropChange}
              className="rounded-md  sm:p-4 sm:pb-3 p-1 pb-0 overflow-hidden sm:max-h-[450px] max-h-[380px] w-full"
            >
              <img src={imagePreview} className="w-full h-full " onLoad={onImageLoaded} />
            </ReactCrop>
            <div className="flex items-center justify-end px-4 gap-3 sm:py-3 py-2 border-t border-mutedseven">
              <button className="bg-light-blue rounded text-blueone text-sm font-semibold px-3 py-1.5" onClick={applyCrop}>Save</button>
              <button className="bg-light-red text-redone rounded text-sm font-semibold  px-3 py-1.5" onClick={cancelCrop}>Cancel</button>
            </div>
          </div>
        </div>
      )}
      <div className="register_bg">
        <div className="grid sm:grid-cols-12 grid-cols-1 mx-4 login-content items-start">
          <div className="xl:col-span-5 lg:col-span-4 sm:col-span-12">
            <div className="login_image sm:flex-row flex-col lg:gap-0 gap-5">
              <Image
                alt="Login Image"
                src="/login-img/logo.svg"
                className="ronded-none mx-w-full max-h-full hidden lg:block  "
              />
              <div className="w-full sm:w-auto">
                <img
                  alt="Login Image"
                  src="/navbar-img/logo.png"
                  className="ronded-none mx-w-full mx-auto max-h-full lg:hidden  "
                />
              </div>
              <h2 className="text-dark-blue font-bold sm:text-5xl text-4xl lg:hidden whitespace-nowrap">
                K. STAR EXPORT
              </h2>
            </div>
          </div>
          <div className="xl:col-span-7  lg:col-span-8 sm:col-span-12 lg:border-s border-mutedtwelve ">
            <div className="xl:ps-28 lg:ps-16 register-content sm:pt-10 lg:pt-0">
              <h5 className="text-dark-blue font-bold text-3xl text-center lg:text-start">
                Register Now
              </h5>
              <form
                onSubmit={formik.handleSubmit}
                className="lg:mt-14 mt-10 mx-auto customs_inputs "
              >
                <div className="flex flex-col lg:gap-7 gap-4">
                  <div className="flex items-center lg:gap-5 gap-3 Register-inputs">
                    <label className="text-darkone sm:text-base text-sm font-medium">
                      Profile Picture
                    </label>
                    <div className="relative upload">
                      {imagePreview ? (
                        <Image
                          src={croppedImageUrl}
                          alt="Profile Preview"
                          className="object-cover"
                          radius="5px"
                          width="100%"
                          height="100%"
                        />
                      ) :
                        <div className="relative">
                          <Image
                            src="/profile-icon/01.webp"
                            alt="Profile Preview"
                            className="object-cover"
                            radius="5px"
                            width="100%"
                            height="100%"
                          />
                          <p className="text-dark-blue absolute top-2/4 start-0 end-0 text-center font-semibold translate-y-[-50%] z-10">Upload Photo</p>
                        </div>}
                      <input
                        type="file"
                        className="absolute top-0 bottom-0 start-0 end-0 opacity-0"
                        onChange={handleFileChange}
                        accept=".png, .jpg, .jpeg"
                        name="profile_photo"
                      />
                      {fileError && (
                        <div className="error-message">{fileError}</div>
                      )}
                    </div>
                  </div>
                  <div className="flex items-center lg:gap-5 gap-3  Register-inputs">
                    <label className="text-darkone sm:text-base text-sm font-medium">
                      Customer Name*
                    </label>
                    <div className="flex-grow w-full lg:w-auto">
                      <Input
                        className="py-0 new-customers-name"
                        clearable
                        classNames={{
                          input: ["bg-transparent", "text-blackone"],
                          innerWrapper: "bg-transparent ",
                          inputWrapper: [
                            "bg-transparent border border-mutedseven shadow-none py-0",
                            "md:h-11 h-10",
                            "!cursor-text",
                          ],
                        }}
                        type="text"
                        value={formik.values.customerName}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        name="customerName"
                      />
                      {formik.touched.customerName &&
                        formik.errors.customerName ? (
                        <div className="error-message">
                          {formik.errors.customerName}
                        </div>
                      ) : null}
                    </div>
                  </div>

                  <div className="flex items-center lg:gap-5 gap-3 Register-inputs">
                    <label className="text-darkone sm:text-base text-sm font-medium">
                      Father Name*
                    </label>
                    <div className="flex-grow w-full lg:w-auto">
                      <Input
                        className="py-0 new-customers-name"
                        clearable
                        classNames={{
                          input: ["bg-transparent", "text-blackone"],
                          innerWrapper: "bg-transparent ",
                          inputWrapper: [
                            "bg-transparent border border-mutedseven shadow-none py-0",
                            "md:h-11 h-10",
                            "!cursor-text",
                          ],
                        }}
                        type="text"
                        value={formik.values.fatherName}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        name="fatherName"
                      />
                      {formik.touched.fatherName && formik.errors.fatherName ? (
                        <div className="error-message">
                          {formik.errors.fatherName}
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="flex items-center lg:gap-5 gap-3 Register-inputs">
                    <label className="text-darkone sm:text-base text-sm font-medium">
                      Surname*
                    </label>
                    <div className="flex-grow w-full lg:w-auto">
                      <Input
                        className="py-0 new-customers-name"
                        clearable
                        classNames={{
                          input: ["bg-transparent", "text-blackone"],
                          innerWrapper: "bg-transparent ",
                          inputWrapper: [
                            "bg-transparent border border-mutedseven shadow-none py-0",
                            "md:h-11 h-10",
                            "!cursor-text",
                          ],
                        }}
                        type="text"
                        value={formik.values.surname}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        name="surname"
                      />
                      {formik.touched.surname && formik.errors.surname ? (
                        <div className="error-message">
                          {formik.errors.surname}
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="flex items-center lg:gap-5 gap-3  Register-inputs">
                    <label className="text-darkone sm:text-base text-sm font-medium">
                      Company Name
                    </label>
                    <div className="flex-grow w-full lg:w-auto">
                      <Input
                        className="py-0 new-customers-name"
                        clearable
                        classNames={{
                          input: ["bg-transparent", "text-blackone"],
                          innerWrapper: "bg-transparent ",
                          inputWrapper: [
                            "bg-transparent border border-mutedseven shadow-none py-0",
                            "md:h-11 h-10",
                            "!cursor-text",
                          ],
                        }}
                        type="text"
                        value={formik.values.companyName}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        name="companyName"
                      />
                    </div>
                  </div>
                  <div className="flex items-center lg:gap-5 gap-3 Register-inputs">
                    <label className="text-darkone sm:text-base text-sm font-medium">
                      Mobile Number*
                    </label>
                    <div className="flex-grow w-full lg:w-auto">
                      <Input
                        className="py-0 new-customers-name"
                        clearable
                        classNames={{
                          input: ["bg-transparent", "text-blackone"],
                          innerWrapper: "bg-transparent ",
                          inputWrapper: [
                            "bg-transparent border border-mutedseven shadow-none py-0",
                            "md:h-11 h-10",
                            "!cursor-text",
                          ],
                        }}
                        type="number"
                        value={formik.values.mobileNumber}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        name="mobileNumber"
                      />
                      {formik.touched.mobileNumber &&
                        formik.errors.mobileNumber ? (
                        <div className="error-message">
                          {formik.errors.mobileNumber}
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="flex items-center lg:gap-5 gap-3 Register-inputs">
                    <label className="text-darkone sm:text-base text-sm font-medium">
                      Village Name*
                    </label>
                    <div className="flex-grow w-full lg:w-auto">
                      <Input
                        className="py-0 new-customers-name"
                        clearable
                        classNames={{
                          input: ["bg-transparent", "text-blackone"],
                          innerWrapper: "bg-transparent ",
                          inputWrapper: [
                            "bg-transparent border border-mutedseven shadow-none py-0",
                            "md:h-11 h-10",
                            "!cursor-text",
                          ],
                        }}
                        type="text"
                        value={formik.values.villageName}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        name="villageName"
                      />
                      {formik.touched.villageName &&
                        formik.errors.villageName ? (
                        <div className="error-message">
                          {formik.errors.villageName}
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="flex items-center lg:gap-5 gap-3 Register-inputs">
                    <label className="text-darkone sm:text-base text-sm font-medium">
                      Dalal Name
                    </label>
                    <div className="flex-grow w-full lg:w-auto">
                      <Input
                        className="py-0 new-customers-name"
                        clearable
                        classNames={{
                          input: ["bg-transparent", "text-blackone"],
                          innerWrapper: "bg-transparent ",
                          inputWrapper: [
                            "bg-transparent border border-mutedseven shadow-none py-0",
                            "md:h-11 h-10",
                            "!cursor-text",
                          ],
                        }}
                        type="text"
                        value={formik.values.agentName}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        name="agentName"
                      />
                      {formik.touched.agentName && formik.errors.agentName ? (
                        <div className="error-message">
                          {formik.errors.agentName}
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="flex items-center lg:gap-5 gap-3 Register-inputs">
                    <label className="text-darkone sm:text-base text-sm font-medium">
                      Dalal Mobile Number
                    </label>
                    <div className="flex-grow w-full lg:w-auto">
                      <Input
                        className="py-0 new-customers-name"
                        clearable
                        classNames={{
                          input: ["bg-transparent", "text-blackone"],
                          innerWrapper: "bg-transparent ",
                          inputWrapper: [
                            "bg-transparent border border-mutedseven shadow-none py-0",
                            "md:h-11 h-10",
                            "!cursor-text",
                          ],
                        }}
                        type="number"
                        value={formik.values.agentMobile}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        name="agentMobile"
                      />
                      {formik.touched.agentMobile &&
                        formik.errors.agentMobile ? (
                        <div className="error-message">
                          {formik.errors.agentMobile}
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="flex items-center lg:gap-5 gap-3 Register-inputs">
                    <label className="text-darkone sm:text-base text-sm font-medium">
                      Password*
                    </label>
                    <div className="flex-grow w-full lg:w-auto">
                      <Input
                        className="py-0 new-customers-name"
                        clearable
                        classNames={{
                          input: ["bg-transparent", "text-blackone"],
                          innerWrapper: "bg-transparent ",
                          inputWrapper: [
                            "bg-transparent border border-mutedseven shadow-none py-0",
                            "md:h-11 h-10",
                            "!cursor-text",
                          ],
                        }}
                        endContent={
                          <button
                            className="focus:outline-none"
                            type="button"
                            onClick={toggleVisibility}
                          >
                            {isVisible ? (
                              <Eye size={24} color="#71717A" />
                            ) : (
                              <EyeSlash size={24} color="#71717A" />
                            )}
                          </button>
                        }
                        type={isVisible ? "text" : "password"}
                        value={formik.values.password}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        name="password"
                        autoComplete="new-password"
                      />
                      {formik.touched.password && formik.errors.password ? (
                        <div className="error-message">
                          {formik.errors.password}
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="lg:flex gap-5 Register-inputs">
                    <label className="hidden lg:block"></label>
                    <div className="flex flex-col gap-5">
                      <Button
                        type="submit"
                        className="form_btn"
                        isDisabled={submitLoading}
                      >
                        <div className="flex items-center justify-center">
                          <div>REGISTER </div>
                          {submitLoading && (
                            <div className="small-loader extra-small-loader ms-4"></div>
                          )}
                        </div>
                      </Button>
                      <div className="flex items-center lg:justify-center gap-0.75 ">
                        <div className="md:text-4.25 text-3.75 text-dark-blue">
                          Existing User?
                        </div>
                        <Link
                          to="/login"
                          className="md:text-4.25 text-3.75 font-semibold text-dark-blue"
                        >
                          Click Here to Login
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
