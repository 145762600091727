import React, { useState, useEffect, useMemo, useRef } from "react";
import {
  Image,
  Button,
  Table,
  TableHeader,
  TableColumn,
  TableBody,
  TableRow,
  TableCell,
} from "@nextui-org/react";
import { Link, useNavigate } from "react-router-dom";
import { CaretRight, CaretDoubleLeft, CaretLeft, CaretDoubleRight } from "@phosphor-icons/react";
// import { All_Tenders } from "./Data";
import "./Style.scss";
import axios from "axios";

export default function ViewBidHistory() {
  const [loading, setLoading] = useState(true);

  const messagesEndRef = useRef(null)

  const navigate = useNavigate();

  const [allTenders, setAllTenders] = useState([]);

  const fetchTenders = async (tenderType) => {
    const token = localStorage.getItem("k_star_token");
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_URL}/auth/getCustomerBidDetails`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const tenders = res.data;

      setAllTenders(tenders);
    } catch (error) {
      console.log("Error While fetching tender : ", error);
    } finally {
      setLoading(false); // Set loading to false after the API request is complete (regardless of success or failure)
    }
  };

  useEffect(() => {
    // Fetch tenders when the component mounts
    fetchTenders("active");
  }, []);

  return (
    <>
      {loading && (
        <div className="loader-container">
          <div className="loader"></div>
        </div>
      )}
      <div className="body-conetnt-header border-b-2 bg-white md:sticky md:top-0 md:z-10 border-grayone">
        <div>
          <h5 className="text-lg leading-8.25 text-blackone font-semibold">
            Bid History
          </h5>
        </div>
      </div>
      <div className="flex-grow">
        <div ref={messagesEndRef} className="overflow-auto">
          <Table
            radius="none"
            removeWrapper
            className="custom-table"
            aria-label="Example static collection table"
          >
            <TableHeader>
              <TableColumn></TableColumn>
              <TableColumn>TENDER ID</TableColumn>
              <TableColumn>TENDER NAME</TableColumn>
              <TableColumn>START DATE</TableColumn>
              <TableColumn>END DATE</TableColumn>
              <TableColumn>STATUS</TableColumn>
            </TableHeader>
            <TableBody>
              {allTenders.map((props) => {
                return (
                  <TableRow
                    className="cursor-pointer"
                    key={props._id}
                    onClick={() => navigate(`/view-bid/${props._id}`)}
                  >
                    <TableCell className="z-0">
                      <div className="w-4.5">
                        {props.status === 'ACTIVE' && <Image
                          alt="Selected Icon"
                          className="rounded-none transition-opacity duration-300 "
                          src="/box-img/blue-box.svg"
                        />}
                        {props.status === 'PENDING' && <Image
                          alt="Selected Icon"
                          className="rounded-none transition-opacity duration-300 "
                          src="/box-img/orange-box.svg"
                        />}
                        {props.status === 'EXPIRED' && <Image
                          alt="Selected Icon"
                          className="rounded-none transition-opacity duration-300 "
                          src="/box-img/red-box.svg"
                        />}
                        {props.status === 'CLOSED' && <Image
                          alt="Selected Icon"
                          className="rounded-none transition-opacity duration-300 "
                          src="/box-img/red-box.svg"
                        />}
                      </div>
                    </TableCell>
                    <TableCell>
                      <div>{props.tenderId}</div>
                    </TableCell>
                    <TableCell>
                      <div>{props.name}</div>
                    </TableCell>
                    <TableCell>
                      <div>
                        {props.startDate &&
                          new Date(props.startDate).toLocaleString("en-GB", {
                            day: "2-digit",
                            month: "2-digit",
                            year: "numeric",
                            hour: "2-digit",
                            minute: "2-digit",
                            hour12: true
                          }).replace('am', 'AM').replace('pm', 'PM')}

                      </div>
                    </TableCell>
                    <TableCell>
                      <div>
                        {props.endDate &&
                          new Date(props.endDate).toLocaleString("en-GB", {
                            day: "2-digit",
                            month: "2-digit",
                            year: "numeric",
                            hour: "2-digit",
                            minute: "2-digit",
                            hour12: true
                          }).replace('am', 'AM').replace('pm', 'PM')}

                      </div>
                    </TableCell>

                    <TableCell>
                      {props.status === 'EXPIRED' && <div className="text-redtwo font-semibold capitalize">{props.status}</div>}
                      {props.status === 'ACTIVE' && <div className="text-blue font-semibold capitalize">{props.status}</div>}
                      {props.status === 'CLOSED' && <div className="text-redtwo font-semibold capitalize">{props.status}</div>}
                      {props.status === 'PENDING' && <div className="text-orange font-semibold capitalize">{props.status}</div>}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </div>
      </div>
    </>
  );
}
